import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BatchStatusActionsService, VersionCheckService } from '@capturum/complete';
import { LoginConfigService } from '@capturum/login';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { InfoTableConfigService } from '@capturum/ui/info-table';
import { CapturumToastModule } from '@capturum/ui/toast';
import { Favicon } from '@core/configs/favicon.config';
import { CustomSettingService } from '@core/services/custom-setting.service';
import { RouteStateService } from '@core/services/route-state.service';
import { NgxFaviconService } from '@features/favicon/services/favicon.service';
import { TranslateService } from '@ngx-translate/core';
import { HtmlMetaService } from '@src/app/core/services/html-meta.service';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DownloadUrlAction } from './core/batch-status-actions/download-url.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [VersionCheckService],
  standalone: true,
  imports: [RouterOutlet, CapturumToastModule],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private versionCheckService: VersionCheckService,
    private themeService: ThemeService,
    private settingService: CustomSettingService,
    private routeStateService: RouteStateService,
    private loginConfigService: LoginConfigService,
    private infoTableConfigService: InfoTableConfigService,
    private titleService: Title,
    private faviconService: NgxFaviconService<Favicon>,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private router: Router,
    private htmlMetaService: HtmlMetaService,
    private batchStatusActionService: BatchStatusActionsService,
  ) {
    this.routeStateService.listenForRouteChanges();
    this.settingService.setGlobalStyles();
    this.setTheme();
    this.infoTableConfigService.setConfig({
      perPageOptions: [
        { label: '10', value: 10 },
        { label: '20', value: 20 },
        { label: '50', value: 50 },
        { label: '100', value: 100 },
      ],
    });
    this.batchStatusActionService.addActions({ download_url: DownloadUrlAction });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.versionCheckService.onUpdateVersion.pipe(takeUntil(this.destroy$)).subscribe(() => {
      return this.handleVersionUpdate();
    });

    this.settingService.settingsChanged$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      return this.handleSettingsChange();
    });

    // set url for canonical link
    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((event: NavigationEnd) => {
        this.htmlMetaService.createCanonicalLink(window.location.href);
      });
  }

  /**
   * Handle settings change
   *
   * @return void
   */
  private handleSettingsChange(): void {
    const key = this.settingService.getValueString('general.tenant_key');
    const productName = this.settingService.getValueString('radboud.tenant_product_name');

    this.loginConfigService.setConfig({
      productName: productName,
      backgroundImageUrl: `/assets/images/${key}/background.png`,
      logoImageUrl: this.settingService.getValueString('general.tenant_logo'),
    });

    this.titleService.setTitle(productName);
    this.faviconService.setCustomFavicon(Favicon[key]);
  }

  /**
   * Give update dialog to user
   *
   * @return void
   */
  private handleVersionUpdate(): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('radboud.app-update.confirm.title'),
      message: this.translateService.instant('radboud.app-update.confirm.message'),
      accept: () => {
        return window.location.reload();
      },
      acceptButtonStyleClass: 'cap-button primary w-100',
      acceptIcon: 'fas fa-sync',
      acceptLabel: this.translateService.instant('radboud.app-update.confirm.accept'),
      rejectVisible: false,
      closeOnEscape: false,
    });
  }

  /**
   * Set initial theme values
   *
   * @return void
   */
  private setTheme(): void {
    this.themeService.initializeTheme();

    this.themeService.setProps({
      [CSSVarNames.Font]: 'Source Sans Pro',
      [CSSVarNames.Primary]: '#b23129',
      [CSSVarNames.PrimaryShadow]: 'rgba(39, 105, 176, 0.15)',
    });
  }
}
