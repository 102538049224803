import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textEllipsis',
  standalone: true,
})
export class TextEllipsisPipe implements PipeTransform {
  public transform(value: string, maxLength = 60): string {
    return value.length <= maxLength ? value : value.substring(0, maxLength - 3) + '...';
  }
}
