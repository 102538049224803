<div class="page-wrapper__heading">
  @if (loading) {
    <div class="w-100 d-flex align-items-center justify-content-between">
      <cap-skeleton height="50px" width="400px"></cap-skeleton>
      <div class="d-flex justify-content-between align-items-center">
        <cap-skeleton height="50px" width="200px"></cap-skeleton>
        <div class="ml-3">
          <cap-skeleton height="50px" width="200px"></cap-skeleton>
        </div>
      </div>
    </div>
  } @else {
    @if (showBack) {
      <div class="page-wrapper__heading__link" (click)="onBackClick.emit()">
        <h1><i class="fas fa-arrow-left mr-4"></i> {{ heading }}</h1>
      </div>
    } @else {
      {{ heading }}
    }
    @if (extraContentTemplate) {
      <div>
        <ng-container *ngTemplateOutlet="extraContentTemplate"></ng-container>
      </div>
    }
    @if (buttons?.length || menuVisible || navigationVisible) {
      <div class="page-wrapper__heading__buttons">
        @for (button of buttons; track button) {
          <ng-container *ngxPermissionsOnly="button?.permissions">
            @if ((button?.hide | observablePipe | async) === false) {
              <cap-button
                [styleClass]="button?.styleClass"
                [disabled]="button?.disabled | observablePipe | async"
                (onClick)="button.callback()"
                [icon]="button?.icon"
                [iconPos]="button?.iconPos"
                [label]="button?.label">
              </cap-button>
            }
          </ng-container>
        }
        @if (menuItems && menuItems?.length > 0 && menuVisible) {
          <app-menu-button [isLoading]="isExporting" [menuItems]="menuItems"> </app-menu-button>
        }
        @if (navigationVisible) {
          <app-navigation-button [navigationButton]="navigationButton"></app-navigation-button>
        }
      </div>
    }
  }
</div>
