import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { SettingService, UserService } from '@capturum/complete';
import { LoginService as CapturumLoginService, LoginConfig } from '@capturum/login';
import { ToastService } from '@capturum/ui/api';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@src/app/features/manage-user/models/user.model';
import { filter } from 'rxjs';
import { AppRoutes } from '../enums/general/routes.enum';
import { AuthResult, SurfConextResponse } from '../models/base.model';

type LoginSuccessResponse = AuthResult<User & { roles }> | SurfConextResponse;

@Injectable({
  providedIn: 'root',
})
export class CustomLoginService extends CapturumLoginService {
  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly formBuilder: UntypedFormBuilder,
    private settingService: SettingService,
    private userService: UserService,
    private authService: AuthService,
  ) {
    super(config, router, activatedRoute, toastService, translateService, formBuilder);
  }

  /**
   * @inheritDoc
   */
  public onLoginSuccess(response: LoginSuccessResponse): void {
    // after login, we need to update the user to get the default cost place and default project, since they are not attribute anymore
    this.userService
      .get(response.user.id, { include: ['defaultCostPlace', 'defaultProject'] })
      .pipe(filter(Boolean))
      .subscribe(() => {
        if (response.user.is_functional_account) {
          super.onLoginSuccess(response);
        } else if (this.activatedRoute?.snapshot?.queryParamMap?.get('redirectUrl')) {
          this.router.navigate([this.activatedRoute?.snapshot?.queryParamMap?.get('redirectUrl')]);
        } else {
          this.router.navigate([AppRoutes.profile]);
        }
      });
  }

  /**
   * Login with SURFconext: redirect to login route and allow user to sign in
   *
   * @return void
   */
  public loginWithSURFconext(): void {
    const key: string = this.settingService.getValueString('general.tenant_key');

    window.location.href = `${environment.baseUrl}/saml2/surfconext_${key}/login`;
  }

  protected prePopulateForms(): void {
    super.prePopulateForms();

    // Let backend do the validation of the password
    this.resetForm.get('password').setValidators([Validators.required]);
  }
}
