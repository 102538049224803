<div class="selectable-items-list">
  @if (formArray && formArray?.controls && formArray?.controls?.length > 0) {
    @for (control of formArray?.controls; track control; let i = $index) {
      <div class="selectable-items-list__item">
        @switch (type) {
          @case ('dropdown') {
            <div class="selectable-items-list__item__dropdown hide-validator">
              <cap-dropdown
                [formControl]="control.get(controlName)"
                [options]="availableItems"
                [placeholder]="(placeholder ? placeholder : 'placeholder.select') | translate"
                [label]="entityLabel + ' ' + (i + 1)"
                [disabled]="disabled"
                [virtualScroll]="true">
              </cap-dropdown>
            </div>
          }
          @case ('input') {
            <div class="selectable-items-list__item__input hide-validator">
              <cap-input
                [formControl]="control.get(controlName)"
                [label]="entityLabel + ' ' + (i + 1)"
                [disabled]="disabled">
              </cap-input>
            </div>
          }
        }
        @if ((i > 0 || showDeleteFirst) && !disabled) {
          <i class="selectable-items-list__item__trash fas fa-trash" (click)="onRemove.emit(i)"> </i>
        }
      </div>
    }
  } @else {
    @if (disabled) {
      <span class="selectable-items-list__no-content">
        {{ 'radboud.items.no-content.label' | translate }}
      </span>
    }
  }

  @if (!disabled) {
    <cap-button
      [label]="'radboud.button.add-entity.label' | translate: { entity: entityLabel?.toLowerCase() }"
      (click)="onAdd.emit()"
      icon="fas fa-plus"
      iconPos="left"
      styleClass="secondary mt-3">
    </cap-button>
  }
</div>
