<div class="files-upload">
  @if (!disabled) {
    <div
      appFileDragAndDrop
      class="files-upload__button w-100 mb-4"
      (click)="fileInput.click()"
      (onFileDrop)="upload($event)">
      <i class="files-upload__button__icon fas fa-cloud-upload"></i>
      <span class="files-upload__button__title">{{ 'radboud.button.upload.title' | translate }}</span>
      <span class="files-upload__button__subtitle">{{ 'radboud.button.upload.subtitle' | translate }}</span>
    </div>
  }

  <input
    #fileInput
    hidden
    type="file"
    class="d-none"
    [attr.accept]="acceptedMimeTypes"
    [attr.multiple]="multiple"
    (change)="upload($event?.target?.files, $event)" />

  @if (files && files?.length > 0) {
    <cap-file-preview-list [showDelete]="!disabled" [files]="files" (onDeleteClick)="onDelete.emit($event)">
    </cap-file-preview-list>
  } @else {
    @if (disabled) {
      <span class="files-upload__no-content">
        {{ 'radboud.items.no-content.label' | translate }}
      </span>
    }
  }
</div>
