/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { UntypedFormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';

enum ListTypes {
  DROPDOWN = 'dropdown',
  INPUT = 'input',
}

@Component({
  selector: 'app-selectable-items-list',
  templateUrl: './selectable-items-list.component.html',
  styleUrls: ['./selectable-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CapturumDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CapturumInputModule,
    CapturumButtonModule,
    TranslateModule,
  ],
})
export class SelectableItemsListComponent {
  @Input() public availableItems: MapItem[];
  @Input() public entityLabel: string;
  @Input() public formArray: UntypedFormArray;
  @Input() public disabled = false;
  @Input() public showDeleteFirst = false;
  @Input() public type: string = ListTypes.DROPDOWN;
  @Input() public controlName = 'id';
  @Input() public placeholder: string;

  @Output() public onAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onRemove: EventEmitter<number> = new EventEmitter<number>();
}
