import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { IpPermissionService } from '@core/services/ip-permission.service';
import { map } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/general/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class IpPermissionGuard {
  constructor(
    private readonly ipPermissionService: IpPermissionService,
    private readonly router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.ipPermissionService.getIpPermissionState().pipe(
      map((ipPermission) => {
        if (!ipPermission?.is_allowed_to_login) {
          this.router.navigate(['/', AppRoutes.portal]);
        }

        return ipPermission?.is_allowed_to_login;
      }),
    );
  }
}
