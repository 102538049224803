import { Injectable } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { BaseDataIndexedDbModel, BaseDataKeyIndexedDbModel, BaseDataValueApiModel } from '@capturum/complete';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  constructor(private translateService: TranslateService) {}

  public list(key: string): Observable<MapItem[]> {
    return from(BaseDataIndexedDbModel.query().where({ key: key }).first()).pipe(
      this.baseDataValuesToMapItem.bind(this),
    );
  }

  public getBaseDataValues(key: string): Observable<BaseDataValueApiModel[]> {
    return from(BaseDataIndexedDbModel.query().where({ key: key }).first()).pipe(
      map((baseData) => {
        return baseData.values;
      }),
    );
  }

  private baseDataValuesToMapItem(source: Observable<any>): Observable<MapItem[]> {
    const translateService = this.translateService;

    return new Observable((subscriber) => {
      source.subscribe({
        next(value: BaseDataKeyIndexedDbModel): void {
          const values = value?.values;

          if (values) {
            subscriber.next(
              values.map((baseDataValue: any) => {
                return {
                  label: translateService.instant(`base-data.${baseDataValue.id}`),
                  value: baseDataValue.id,
                };
              }),
            );
          }

          subscriber.complete();
        },
      });
    });
  }
}
