import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { ApiService, ListOptions } from '@capturum/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@capturum/ui/api';
import { LoadingService } from '@capturum/ui/loader';
import { catchError, filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { BaseModel } from '@core/models/base.model';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { Entities } from '@core/enums/general/entities.enum';
import { ManageHeaderButton } from '@core/models/manage-header-button.model';
import { ButtonMenuItem } from '@core/models/button-menu-item.model';
import { Layout } from '@core/enums/general/layout.enum';
import { SettingService } from '@capturum/complete';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormUtils } from '@core/utils/form.utils';
import { RouteStateService } from '@src/app/core/services/route-state.service';

@Component({
  template: '',
  standalone: true,
})
export class ManageDetailComponent<T extends BaseModel = any> implements OnInit, OnDestroy {
  public form: UntypedFormGroup = new UntypedFormGroup({});
  public model: T;
  public formGroups: Record<string, AbstractControl | UntypedFormGroup>;
  public isEdit: boolean;
  public buttons: ManageHeaderButton[];
  public menuItems: ButtonMenuItem[];
  public model$: Observable<T>;
  public formDisabled$: Observable<boolean>;
  public submitted$: Observable<boolean>;
  public loading$: Observable<boolean>;
  public layouts: typeof Layout = Layout;
  public applyFormState = true;

  protected entity: Entities;
  protected loadingSubject = new BehaviorSubject<boolean>(true);
  protected apiOptions: Partial<ListOptions>;
  protected identifier = 'id';
  protected getMethod = 'get';
  protected subs: SubSink = new SubSink();
  protected changeDetectorRef: ChangeDetectorRef;
  protected loadingService: LoadingService;
  protected translateService: TranslateService;
  protected apiService: ApiService<T>;
  protected toastService: ToastService;
  protected settingService: SettingService;
  protected route: ActivatedRoute;
  protected router: Router;
  protected routeStateService: RouteStateService;
  protected formDisabledSubject = new BehaviorSubject<boolean>(false);

  private updateModel = new BehaviorSubject<boolean>(true);
  private submitted = new Subject<boolean>();
  private originalModel: T;

  constructor(protected injector: Injector) {
    this.route = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.routeStateService = this.injector.get(RouteStateService);
    this.changeDetectorRef = this.injector.get(ChangeDetectorRef);
    this.toastService = this.injector.get(ToastService);
    this.translateService = this.injector.get(TranslateService);
    this.loadingService = this.injector.get(LoadingService);
    this.settingService = this.injector.get(SettingService);

    this.formDisabled$ = this.formDisabledSubject.asObservable();
    this.submitted$ = this.submitted.asObservable();
    this.loading$ = this.loadingSubject.asObservable();
  }

  public ngOnInit(): void {
    this.isEdit = this.isEditMode();
    this.buttons = this.getDefaultButtons();
    this.toggleLoadingState(this.getInitialLoadingState());
    this.setModelObservable();

    this.subs.add(
      this.updateModel
        .asObservable()
        .pipe(
          switchMap(() => {
            return this.model$.pipe(first());
          }),
          catchError((error) => {
            this.toggleLoadingState(false);

            return throwError(error);
          }),
        )
        .subscribe(
          (model: T) => {
            this.model = model;
            this.originalModel = model;
            this.toggleLoadingState(this.getLoadingStateAfterEntityGet());
            this.afterGetEntity(model);

            if (this.applyFormState) {
              this.applyFormStateAfterEntityGet();
            }

            this.changeDetectorRef.markForCheck();
          },
          () => {
            this.router.navigate([`../${AppRoutes.overview}`, { relativeTo: this.route }]);
          },
        ),
    );
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Route back to overview page
   *
   * @return void
   */
  public routeBack(): void {
    if (this.routeStateService.getPreviousUrlAsString()) {
      this.router.navigate([this.routeStateService.getPreviousUrlAsString()]);

      return;
    }

    this.router.navigate([`../${AppRoutes.overview}`], { relativeTo: this.route });
  }

  /**
   * Enable/disable the form
   *
   * @param onSave: boolean
   * @return void
   */
  public toggleFormEditor(onSave = false): void {
    if (onSave) {
      this.model = { id: this.model.id, ...this.originalModel };
    } else {
      this.patchFormWithModel(this.originalModel);
    }

    this.toggleFormState();
  }

  /**
   * Submit the form
   */
  public submit(): void {
    this.submitted.next(true);

    if (this.isFormValid()) {
      this.loadingService.toggleLoading(true);
      const data = this.getPostData();
      const saveMethod = this.getSaveMethod(data);

      saveMethod
        .pipe(
          finalize(() => {
            this.loadingService.hideLoader();
          }),
          catchError((error) => {
            this.loadingService.hideLoader();

            return throwError(error);
          }),
        )
        .subscribe((response) => {
          this.model = { ...this.originalModel, ...data, ...response };
          this.originalModel = { ...this.originalModel, ...data, ...response };

          if (this.isEdit) {
            this.afterSuccessfullUpdateRequest(response);
          } else {
            this.afterSuccesfullStoreRequest(response);
          }
        });
    } else {
      this.toastService.error(
        this.translateService.instant('toast.error.title'),
        this.translateService.instant('radboud.validation-errors.message'),
      );
    }
  }

  /**
   * Toggle form state
   *
   * @return void
   */
  protected toggleFormState(): void {
    if (this.form.disabled) {
      this.form.enable();
      this.formDisabledSubject.next(false);
    } else {
      this.form.disable();
      this.formDisabledSubject.next(true);
    }
  }

  /**
   * Apply form state after entity get
   *
   * @return void
   */
  protected applyFormStateAfterEntityGet(): void {
    if (this.form.enabled) {
      this.toggleFormEditor();
    }
  }

  /**
   * Get the initial loading state
   *
   * @return boolean
   */
  protected getInitialLoadingState(): boolean {
    return this.isEdit;
  }

  /**
   * Toggle the loading state
   *
   * @param loading: boolean
   * @return void
   */
  protected toggleLoadingState(loading: boolean): void {
    this.loadingSubject.next(loading);
  }

  /**
   * Get loading state after entity get
   *
   * @return boolean
   */
  protected getLoadingStateAfterEntityGet(): boolean {
    return false;
  }

  /**
   * Get post data
   *
   * @return any
   */
  protected getPostData(): any {
    return Object.keys(this.formGroups).reduce((formData, formGroupName) => {
      return { ...formData, ...this.form.get(formGroupName).value };
    }, {});
  }

  /**
   * Apply current settings to field visibility
   *
   * @param forms: FormlyFieldConfig[][]
   * @return void
   */
  protected applySettingsToFormlyFields(forms: FormlyFieldConfig[][]): void {
    for (const formlyFields of forms) {
      FormUtils.applySettingsToFields(formlyFields, this.settingService);
    }
  }

  /**
   * Check if edit mode
   *
   * @return boolean
   */
  protected isEditMode(): boolean {
    return !!this.route.snapshot.paramMap.get(this.identifier);
  }

  /**
   * Update the model property by doing a new network request to get the resource
   */
  protected updateEntity(): void {
    this.updateModel.next(true);
  }

  /**
   * Patch the form with the given model
   *
   * @param model
   */
  protected patchFormWithModel(model: T): void {
    for (const key in this.formGroups) {
      if (this.formGroups.hasOwnProperty(key)) {
        this.form.patchValue({
          [key]: { ...model },
        });
      }
    }
  }

  /**
   * Set the model$ observable property
   *
   * @return void
   */
  protected setModelObservable(): void {
    this.model$ = this.route.paramMap.pipe(
      filter((paramMap) => {
        return !!paramMap.get('id');
      }),
      tap(() => {
        return (this.isEdit = true);
      }),
      switchMap((paramMap) => {
        return this.getGetMethod(paramMap.get('id'));
      }),
      map((model) => {
        return this.mapModel(model);
      }),
    );
  }

  /**
   * Map the model to generic
   *
   * @param model: M
   * @return T
   */
  protected mapModel<M = any>(model: M): T {
    return model as unknown as T;
  }

  /**
   * Get save method to use
   *
   * @param data: any
   * @return Observable<T>
   */
  protected getSaveMethod(data: any): Observable<T> {
    return this.isEdit ? this.apiService.update({ id: this.model.id, ...data }) : this.apiService.create(data);
  }

  /**
   * Get the GET Method for the model
   *
   * @param id: string
   * @return Observable<T>
   */
  protected getGetMethod(id: string): Observable<T> {
    return this.apiService[this.getMethod](id, this.apiOptions);
  }

  /**
   * Check if form is valid
   *
   * @return boolean
   */
  protected isFormValid(): boolean {
    return this.form.valid;
  }

  /**
   * Hook to run after a successfull update request
   *
   * @param response: T
   * @return void
   */
  protected afterSuccessfullUpdateRequest(response: T): void {
    this.toastService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant('toast.success.update', {
        entity: this.translateService.instant(`radboud.${this.entity}.entity_name`),
      }),
    );

    this.toggleFormEditor(true);
  }

  /**
   * Hook to run after a successfull store request
   *
   * @param response: T
   * @return void
   */
  protected afterSuccesfullStoreRequest(response: T): void {
    this.toastService.success(
      this.translateService.instant('toast.success.title'),
      this.translateService.instant('toast.success.create', {
        entity: this.translateService.instant(`radboud.${this.entity}.entity_name`),
      }),
    );

    this.toggleFormEditor(true);
    this.router.navigate([`../${AppRoutes.overview}`], { relativeTo: this.route });
    this.isEdit = true;
  }

  /**
   * Get the default buttons for a manage detail page
   *
   * @return ManageHeaderButton[]
   */
  protected getDefaultButtons(): ManageHeaderButton[] {
    return [
      {
        label: this.translateService.instant('button.edit'),
        styleClass: 'primary',
        icon: 'fas fa-pencil',
        iconPos: 'left',
        hide: this.formDisabled$.pipe(
          map((disabled) => {
            return !disabled || !this.isEdit;
          }),
        ),
        permissions: [`radboud.${this.entity}.manage`],
        callback: () => {
          return this.toggleFormEditor();
        },
      },
      {
        label: this.translateService.instant('button.cancel'),
        styleClass: 'secondary mr-3',
        hide: this.formDisabled$,
        permissions: [`radboud.${this.entity}.manage`],
        callback: () => {
          return this.isEdit ? this.toggleFormEditor(false) : this.routeBack();
        },
      },
      {
        label: this.translateService.instant('button.submit'),
        styleClass: 'primary',
        icon: 'fas fa-save',
        iconPos: 'left',
        hide: this.formDisabled$,
        permissions: [`radboud.${this.entity}.manage`],
        callback: () => {
          return this.submit();
        },
      },
    ];
  }

  /**
   * Hook to run after a successful get request
   *
   * @param model: T
   * @return void
   */
  protected afterGetEntity(model: T): void {
    //
  }
}
