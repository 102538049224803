import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '@features/device/models/device.model';

@Pipe({
  name: 'deviceImage',
  standalone: true,
})
export class DeviceImagePipe implements PipeTransform {
  public transform(device: Device): string {
    if (device?.images) {
      const deviceImages = [...(device?.images || [])].sort((a, b) => {
        return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
      });

      return deviceImages[0]?.public_url ?? null;
    }
  }
}
