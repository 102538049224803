import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appFileDragAndDrop]',
  standalone: true,
})
export class FileDragAndDropDirective {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onFileDrop: EventEmitter<FileList> = new EventEmitter<FileList>();

  @HostBinding('style.background-color') public background = '#fff';
  @HostBinding('style.opacity') public opacity = '1';

  @HostListener('dragover', ['$event'])
  public onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#9ecbec';
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#fff';
    this.opacity = '1';
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.background = '#fff';
    this.opacity = '1';

    const files = event.dataTransfer.files;

    if (files.length > 0) {
      this.onFileDrop.emit(files);
    }
  }
}
