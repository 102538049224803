import { TranslateService } from '@ngx-translate/core';
import { InjectionToken } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { format, startOfDay, addMinutes, endOfDay, parse, eachMinuteOfInterval } from 'date-fns';
import { DatesFormats } from '../enums/general/dates-formats.enum';
import { DeviceTimeBlockMinute } from '@core/enums/device/device-time-block-minute.enum';
import { TimeSlot } from '@features/timeslot/models/timeslot.model';
import { Device } from '@features/device/models/device.model';

export const HOURS_BLOCK_OPTIONS: InjectionToken<MapItem[]> = new InjectionToken<MapItem[]>('HOURS_BLOCK_OPTIONS');

/**
 * Create the time intervals for dropdowns
 *
 * @param intervalMinutes: number
 * @param amountOfHours: number
 * @param isStart: boolean
 * @return MapItem[]
 */
export function createTimeSlots(
  isStart = false,
  intervalMinutes = 60,
  amountOfHours = 24,
  addHalfHour = false,
): MapItem[] {
  let intervalInMinutes = intervalMinutes;

  if (intervalMinutes === 0) {
    intervalInMinutes = 60;
  }

  const result = [];
  const amountOfIterations = getAmountOfTimeIterations(intervalInMinutes, amountOfHours);

  for (let i = 0; i < amountOfIterations; i++) {
    const currentDate = startOfDay(new Date());
    const newDate = addMinutes(currentDate, intervalInMinutes * i + (addHalfHour ? 30 : 0));
    const timeslot = format(newDate, DatesFormats.defaultTime);

    result.push({
      value: timeslot,
      label: timeslot,
    });
  }

  if (!isStart) {
    const endOfDayValue = format(endOfDay(new Date()), 'HH:mm');

    result.push({
      value: endOfDayValue,
      label: '24:00',
    });
  }

  return result;
}

export function getHourblockOptions(translateService: TranslateService): MapItem[] {
  const options = [];

  for (let hour = 0; hour <= 24; hour++) {
    options.push({
      value: hour,
      label: `${hour} ${translateService.instant('radboud.report.hours.label')}`,
    });
  }

  options.push({
    value: 168,
    label: `168 ${translateService.instant('radboud.report.hours.label')}`,
  });

  return options;
}

export function convertTimeSlotSelectedValuesToObject(timeslotSelectedValues: [string]): any[] {
  return Array.isArray(timeslotSelectedValues)
    ? timeslotSelectedValues.map((timeSlotId) => {
        return { id: timeSlotId };
      })
    : [];
}

export function getAmountOfTimeIterations(intervalMinutes: number, amountOfHours: number): number {
  if (intervalMinutes === undefined || amountOfHours === undefined || intervalMinutes === 0) {
    return 0;
  }

  return Math.ceil(amountOfHours * (60 / intervalMinutes));
}

export function formatTimeSlotTimeWithoutSeconds(time = '00:00:00'): string {
  const splitTime = time?.split(':');

  return `${splitTime[0]}:${splitTime[1] ? splitTime[1] : '00'}`;
}

export function getUpdateBookingPeriod(date: string, time: string): string {
  const formattedDate = format(parse(date, DatesFormats.defaultDate, new Date()), DatesFormats.defaultDate);
  const dateTime = `${formattedDate} ${time}`;

  return format(parse(dateTime, DatesFormats.defaultDateWithTime, new Date()), DatesFormats.defaultDateWithTime);
}

export function getTimeBlock(timeslot: TimeSlot, device: Device): number {
  if (device?.allow_continuous_booking) {
    return DeviceTimeBlockMinute.halfHour;
  }

  return device?.timeblock_minutes || timeslot.timeblock_minutes || device?.domain_timeblock_minutes;
}

export function getTimeSlotOptionsByDevice(device: Device, timeSlot: TimeSlot, start: boolean): MapItem[] {
  let timeSlots: MapItem[];
  let addHalfHour = false;
  const timeblock = getTimeBlock(timeSlot, device);
  const [, timeslotStartMinutes] = timeSlot.time_start.split(':');

  if (parseInt(timeslotStartMinutes, 10) !== 0) {
    addHalfHour = true;
  }

  const timeEndValue = timeSlot.time_end === '24:00' ? '23:59' : timeSlot.time_end;

  if (timeSlot.next_day || timeblock === DeviceTimeBlockMinute.day) {
    // If timeblock is 24 hours, or ends on next day, only 1 option should be shown
    timeSlots = [
      {
        value: start ? timeSlot.time_start : timeEndValue,
        label: start ? timeSlot.time_start : timeSlot.time_end,
      },
    ];
  } else {
    const [startHour, startMinutes] = timeSlot.time_start.split(':').map((time) => {
      return parseInt(time, 10);
    });
    const [endHour, endMinutes] = timeEndValue.split(':').map((time) => {
      return parseInt(time, 10);
    });

    timeSlots = eachMinuteOfInterval(
      {
        start: new Date(0, 0, 0, startHour, startMinutes),
        end: new Date(0, 0, 0, endHour, endMinutes),
      },
      { step: timeblock },
    ).map((date) => {
      return {
        value: format(date, 'HH:mm'),
        label: format(date, 'HH:mm'),
      };
    });

    if (timeSlot.time_end === '24:00' && !start) {
      timeSlots.push({
        value: '23:59',
        label: '24:00',
      });
    }
  }

  return timeSlots;
}

export function getTimeblockMinuteOptions(): MapItem[] {
  return [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 60, label: '60' },
  ];
}

export function getExtendedTimeblockMinuteOptions(): MapItem[] {
  return [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 60, label: '60' },
    { value: 120, label: '120' },
    { value: 240, label: '240' },
    { value: 300, label: '300' },
    { value: 480, label: '480' },
    { value: 960, label: '960' },
    { value: 1440, label: '1440' },
  ];
}

export function getTimeblockHourOptions(): MapItem[] {
  return [
    { value: 0.25, label: '0.25' },
    { value: 0.5, label: '0.5' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 8, label: '8' },
    { value: 16, label: '16' },
    { value: 24, label: '24' },
  ];
}

export function getTimeSlotIntervalOptions(): MapItem[] {
  return [
    { label: '60', value: ['00'] },
    { label: '30', value: ['00', '30'] },
    { label: '15', value: ['00', '15', '30', '45'] },
    { label: '10', value: ['00', '10', '20', '30', '40', '50'] },
    { label: '5', value: ['00', '05', '10', '15', '20', '30', '35', '40', '45', '50', '55'] },
  ];
}
