import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, Inject, Injectable, Injector, Type } from '@angular/core';
import { CapturumDialogService } from '@capturum/ui/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DialogStateService } from '@core/services/dialog-state.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomDialogService extends CapturumDialogService {
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    appRef: ApplicationRef,
    injector: Injector,
    private dialogStateService: DialogStateService,
    @Inject(DOCUMENT)
    document: Document,
  ) {
    super(componentFactoryResolver, appRef, injector, document);
  }

  /**
   * Open a dialog
   *
   * @param componentType: Type<any>
   * @param config: DynamicDialogConfig
   * @param containerComponent: Type<any>
   * @return DynamicDialogRef
   */
  public open(componentType: Type<any>, config: DynamicDialogConfig, containerComponent?: Type<any>): DynamicDialogRef {
    const ref = super.open(componentType, config, containerComponent);

    this.dialogStateService.setDialogState(true);

    ref.onClose.pipe(take(1)).subscribe(() => {
      return this.dialogStateService.setDialogState(false);
    });

    return ref;
  }
}
