import { ModuleWithProviders, NgModule } from '@angular/core';
import { Dictionary, NgxFaviconConfig } from './interfaces/favicon.interface';
import { NGX_FAVICON_CONFIG } from './providers/favicon.tokens';
import { NgxFaviconService } from './services/favicon.service';

class NgxFaviconConfigurationRequiredError extends Error {
  constructor() {
    super(
      'Please provide a configuration when calling "NgxFaviconModule.forRoot(CONF GOES HERE)"',
    );
  }
}

@NgModule()
export class NgxFaviconModule {
  static forRoot<CustomFaviconConfig extends Dictionary<string>>(
    conf: NgxFaviconConfig<CustomFaviconConfig>,
  ): ModuleWithProviders<NgxFaviconModule> {
    return {
      ngModule: NgxFaviconModule,
      providers: [
        NgxFaviconService,
        { provide: NGX_FAVICON_CONFIG, useValue: conf },
      ],
    };
  }
}
