import { Pipe, PipeTransform } from '@angular/core';
import { Device } from '@src/app/features/device/models/device.model';
import { endOfDay, isWithinInterval, startOfDay } from 'date-fns';

@Pipe({
  standalone: true,
  name: 'deviceShowMessage',
})
export class DeviceShowMessagePipe implements PipeTransform {
  public transform(device: Device): boolean {
    if (device?.message && !device.message_period?.length) {
      return true;
    }

    const startMessagePeriode: Date = device.message_period?.length && device.message_period[0];
    const endMessagePeriode: Date = device.message_period?.length && device.message_period[1];
    const today = new Date();

    if (!startMessagePeriode || !endMessagePeriode) {
      return false;
    }

    return isWithinInterval(today, {
      start: startOfDay(new Date(startMessagePeriode)),
      end: endOfDay(new Date(endMessagePeriode)),
    });
  }
}
