<div class="search-card">
  <div class="search-card__header">
    {{ title }}
  </div>

  <div class="search-card__content">
    @if (searchControl) {
      <cap-input [formControl]="searchControl" iconLeft="fas fa-search" [placeholder]="inputPlaceholder"> </cap-input>
    }

    <div class="search-card__content__results mt-2">
      @if ((loading$ | async) === false) {
        @if (devices.length) {
          @for (item of devices; track item) {
            <div class="search-card__content__results__item">
              <ng-container [ngTemplateOutlet]="resultCardTemplate" [ngTemplateOutletContext]="{ item: item }">
              </ng-container>
            </div>
          }
        } @else {
          @if (hasSearched) {
            <span class="search-card__content__no-results">
              {{ 'radboud.global-search.no-results.label' | translate }}
            </span>
          }
        }
      } @else {
        <app-loader styleClass="mx-auto"></app-loader>
      }
    </div>
  </div>
</div>
