import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable, from } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { TranslationIndexedDbModel, TranslationResponse } from '@capturum/complete';
import { TranslateService } from '@ngx-translate/core';
import User from '@capturum/auth/lib/user.interface';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class CustomTranslationService extends ApiService<any> {
  constructor(
    private api: ApiHttpService,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {
    super(api);
  }

  /**
   * Get the translations for the current user
   *
   * @return Observable<any>
   */
  public getTranslations(authenticated: boolean): Observable<any> {
    const uri: string = authenticated ? '/role' : '/public';

    return this.apiHttp.get(`/${uri}/translation`).pipe(
      map((response: { data: any }) => {
        return response.data;
      }),
      tap((response) => {
        for (const key in response) {
          if (response.hasOwnProperty(key)) {
            TranslationIndexedDbModel.query().put({ id: key, value: response[key] });
          }
        }
      }),
    );
  }

  /**
   * Load translations and set default or current users language
   *
   * @param authenticated: boolean
   * @param authenticatedUser
   * @return Observable<boolean>
   */
  public loadTranslations(authenticated: boolean, authenticatedUser: User = null): Observable<boolean> {
    const uri: string = authenticated ? '/role' : '/public';

    return new Observable<boolean>((observer) => {
      this.apiHttp
        .get(`${uri}/translation`)
        .pipe(
          map((response: any) => {
            return response.data;
          }),
          map((translations) => {
            const result = [];

            for (const language in translations) {
              if (translations.hasOwnProperty(language)) {
                // tslint:disable-next-line
                for (const translationKey in translations[language]) {
                  if (translations[language].hasOwnProperty(translationKey)) {
                    if (translationKey === 'base-data' && typeof translations[language][translationKey] === 'object') {
                      translations[language][translationKey] = Object.keys(
                        translations[language][translationKey],
                      ).reduce((destination, key) => {
                        destination[key.toLowerCase()] = translations[language][translationKey][key];

                        return destination;
                      }, {});
                    }
                  }

                  result.push({ id: language, value: translations[language] });
                }
              }
            }

            return result;
          }),
          switchMap((translations) => {
            return from(TranslationIndexedDbModel.query().bulkPut(translations));
          }),
          switchMap((res) => {
            this.translateService.setDefaultLang('en');

            const language = this.translateService.defaultLang;

            // Make sure the translations are loaded correctly
            this.translateService
              .reloadLang(language)
              .pipe(first())
              .subscribe(() => {
                this.translateService.use(language);
              });

            // Make sure the translations are loaded by getting a translation and waiting for a successful retrieval of the translation
            return this.translateService.get('button.add');
          }),
        )
        .subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          },
        );
    });
  }

  /**
   * Load Base data translations and set default or current users language
   *
   * @param authenticated: boolean
   * @param authenticatedUser
   * @return Observable<boolean>
   */
  public loadBaseDataTranslations(
    authenticated: boolean,
    authenticatedUser: User = null,
    filteredTranslations: TranslationResponse,
  ): Observable<boolean> {
    const uri: string = authenticated ? '/role' : '/public';

    return new Observable<boolean>((observer) => {
      this.apiHttp
        .get(`${uri}/translation`)
        .pipe(
          map((response: any) => {
            return response.data;
          }),
          map((translations) => {
            const result = [];

            for (const language in filteredTranslations) {
              if (translations.hasOwnProperty(language)) {
                // tslint:disable-next-line
                for (const translationKey in translations[language]) {
                  if (translationKey === 'base-data' && typeof translations[language][translationKey] === 'object') {
                    filteredTranslations[language][translationKey] = Object.keys(
                      translations[language][translationKey],
                    ).reduce((destination, key) => {
                      destination[key.toLowerCase()] = translations[language][translationKey][key];

                      return destination;
                    }, {});
                  }
                }
                result.push({ id: language, value: filteredTranslations[language] });
              }
            }

            return result;
          }),
          switchMap((translations) => {
            return from(TranslationIndexedDbModel.query().bulkPut(translations));
          }),
          switchMap((res) => {
            this.translateService.setDefaultLang('en');

            const language = this.translateService.defaultLang;

            // Make sure the translations are loaded correctly
            this.translateService
              .reloadLang(language)
              .pipe(first())
              .subscribe(() => {
                this.translateService.use(language);
              });

            // Make sure the translations are loaded by getting a translation and waiting for a successful retrieval of the translation
            return this.translateService.get('button.add');
          }),
        )
        .subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          },
        );
    });
  }

  public loadFilteredTranslations(groups?: string[], locale?: string): Observable<any> {
    const joinedGroups: string = groups ? groups.join(',') : null;
    const currentLanguage = locale ?? this.translateService.currentLang;
    let params;

    if (joinedGroups) {
      params = { ...params, groups: joinedGroups };
    }

    if (currentLanguage) {
      params = { ...params, locale: currentLanguage };
    }

    return this.api.get<{ data: TranslationResponse }>('/translation/filtered', { params }).pipe(
      map((response) => {
        return response.data;
      }),
    );
  }

  protected mapTranslationResponse(translations: TranslationResponse): { id: string; value: Record<string, string> }[] {
    const translationRecords = [];

    for (const language in translations) {
      if (translations.hasOwnProperty(language)) {
        for (const translationKey in translations[language]) {
          if (translationKey === 'base-data' && typeof translations[language][translationKey] === 'object') {
            // Make sure all keys (GUIDs) are lowercase, because MSSQL returns them in uppercases
            translations[language][translationKey] = Object.keys(translations[language][translationKey]).reduce(
              (destination, key) => {
                destination[key.toLowerCase()] = translations[language][translationKey][key];

                return destination;
              },
              {},
            );
          }
        }

        translationRecords.push({ id: language, value: translations[language] });
      }
    }

    return translationRecords;
  }
}
