import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthService, AuthModule } from '@capturum/auth';
import { ExtendedUser } from '@core/models/user.model';
import { BaseDataValueApiModel, BaseDataModule } from '@capturum/complete';
import { BookingStatus } from '@core/enums/booking/booking-status.enum';
import { BaseDataKey } from '@core/enums/general/base-data-key.enum';
import { AvailabilityBookingDay, AvailabilityBookingHour } from '@core/models/availability-booking-day.model';
import { BaseDataService } from '@core/services/base-data.service';
import { Device } from '@features/device/models/device.model';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DatesFormats } from '@src/app/core/enums/general/dates-formats.enum';
import { Booking } from '@src/app/features/booking/models/booking.model';
import { formatISO, isEqual, startOfISOWeek, add, addDays, subDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { Observable } from 'rxjs';
import { isoToDate, isoToDMY, isoToHM } from '@core/utils/date.utils';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { BookingDatePipe } from '@shared/pipes/booking-date.pipe';
import { SafeDatePipe } from '../../pipes/safe-date.pipe';
import { SharedModule } from 'primeng/api';
import { LoaderComponent } from '../loader/loader.component';
import { FormsModule } from '@angular/forms';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgClass, AsyncPipe } from '@angular/common';
import { Roles } from '@core/enums/general/roles.enum';

interface BookingHour {
  from: string;
  to: string;
  user_id: string;
  blocked: boolean;
  id: string;
  is_waiting_for_approval: boolean;
}

interface CalendarHour {
  hour: number;
  status?: string;
  bookingId: string;
  hide?: boolean;
  percentage: number;
  maskPosition: number;
}

@Component({
  selector: 'app-device-availability-calendar',
  templateUrl: './device-availability-calendar.component.html',
  styleUrls: ['./device-availability-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CapturumCalendarModule,
    FormsModule,
    LoaderComponent,
    OverlayPanelModule,
    SharedModule,
    NgClass,
    AuthModule,
    AsyncPipe,
    TranslateModule,
    BaseDataModule,
    SafeDatePipe,
    BookingDatePipe,
    SharedModule,
    CapturumButtonModule,
  ],
})
export class DeviceAvailabilityCalendarComponent implements OnInit {
  @ViewChild('tooltipPanel')
  public tooltipPanel: OverlayPanel;

  @Input() public device: Device;
  @Input() public sameLocationDeviceBookings: Booking[] = [];
  @Input() public set bookings(bookings: Booking[]) {
    this._bookings = bookings;
    this.populateCalendar();
  }

  @Input() public amountOfDays = 7;
  @Input() public amountOfHours = 24;

  @Input() public detailPage = false;

  @Input()
  public set date(date: string) {
    this._date = date;
    this.populateCalendar();
    this.cdr.markForCheck();
  }

  @Output() public dateChange: EventEmitter<string> = new EventEmitter<string>();

  public get date(): string {
    return this._date;
  }

  public _date: string = formatISO(new Date());
  public defaultDate: Date = new Date();
  public weekNumber: string;
  public days: AvailabilityBookingDay[];
  public hours: number[] = [];
  public bookingStatuses$: Observable<BaseDataValueApiModel[]>;
  public currentUser: ExtendedUser;
  public focusedBooking: Booking;
  public hasRoleUser = false;
  private _bookings: Booking[];

  constructor(
    private translateService: TranslateService,
    private baseDataService: BaseDataService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {
    this.currentUser = this.authService.getUser();
  }

  public ngOnInit(): void {
    this.hasRoleUser = this.currentUser?.currentRole?.key === Roles.user;
    this.populateCalendar();
    this.bookingStatuses$ = this.baseDataService.getBaseDataValues(BaseDataKey.bookingStatus);
  }

  /**
   * Set the currently active booking shown in the tooltip
   *
   * @param bookingId: string
   * @return void
   */
  public setCurrentFocusedBooking(bookingId: string): void {
    this.focusedBooking = [...this._bookings, ...this.getBookingsForDevices(this.sameLocationDeviceBookings)].find(
      (item) => {
        return item.id === bookingId;
      },
    );
  }

  public previousWeek(): void {
    const currentDate = new Date(this._date);

    this._date = formatISO(subDays(currentDate, 7), { representation: 'date' });
    this.dateChange.emit(formatISO(subDays(currentDate, 7), { representation: 'complete' }));
    this.populateCalendar();
  }

  public nextWeek(): void {
    const currentDate = new Date(this._date);

    this._date = formatISO(addDays(currentDate, 7), { representation: 'date' });
    this.dateChange.emit(formatISO(addDays(currentDate, 7), { representation: 'complete' }));
    this.populateCalendar();
  }

  public onHourClick(hour: AvailabilityBookingHour, event: MouseEvent): void {
    if (this.detailPage) {
      return;
    }

    this.setCurrentFocusedBooking(hour?.bookingId);

    !hour.hide ? this.tooltipPanel.toggle(event) : null;
  }

  protected getBookingForHour(bookings: BookingHour[], hour: number): BookingHour[] {
    return bookings.filter((booking) => {
      const fromHour = Number(booking.from.split(':')[0]);
      const toHour = Number(booking.to.split(':')[0]);
      const toMinutes = Number(booking.to.split(':')[1]);

      return fromHour <= hour && (toHour >= hour + 1 || (toHour >= hour && toMinutes > 0));
    });
  }

  protected getBookingsForDay(bookings: Booking[], day: string): BookingHour[] {
    return bookings
      ? bookings
          .filter((booking) => {
            return isoToDMY(booking.period_start) === day || isoToDMY(booking.period_end) === day;
          })
          .map((booking) => {
            let from = isoToHM(booking?.period_start);
            let to = isoToHM(booking?.period_end);

            // Set 'to' to end of the day if booking ends next day
            if (
              !isEqual(
                isoToDate(booking.period_start, false, true).setHours(0, 0, 0, 0),
                isoToDate(booking.period_end, false, true).setHours(0, 0, 0, 0),
              ) &&
              format(new Date(booking.period_start), DatesFormats.defaultDateReversed) === day
            ) {
              to = '24:00';
            }

            // Set 'from' to beginning of the day if booking started previous day
            if (
              !isEqual(isoToDate(booking.period_start, true), isoToDate(booking.period_end, true)) &&
              format(new Date(booking.period_end), DatesFormats.defaultDateReversed) === day
            ) {
              from = '00:00';
            }

            return {
              is_waiting_for_approval: booking?.is_waiting_for_approval,
              from: from,
              to: to,
              user_id: booking?.user_id || booking?.user?.id,
              blocked: booking?.blocked,
              id: booking?.id,
            };
          })
      : [];
  }

  protected createHourForCalendar(
    bookingForHour: BookingHour,
    adjacentBookingForHour: boolean,
    hour: number,
  ): CalendarHour {
    const fromHour = Number(bookingForHour.from.split(':')[0]);
    const fromMinutes = Number(bookingForHour.from.split(':')[1]);
    const toHour = Number(bookingForHour.to.split(':')[0]);
    const toMinutes = Number(bookingForHour.to.split(':')[1]);
    const hasDivergentPercentageFrom = fromHour === hour;
    const hasDivergentPercentageTo = toHour === hour;
    let percentage = 100;
    let maskPosition = 0;
    let status: string = BookingStatus.available;

    if (hasDivergentPercentageFrom) {
      const minutes = fromHour === toHour ? toMinutes - fromMinutes : 60 - fromMinutes;

      percentage = (minutes / 60) * 100;
      maskPosition = (fromMinutes / 60) * 100;
    } else if (hasDivergentPercentageTo) {
      percentage = (toMinutes / 60) * 100;
      maskPosition = 0;
    }

    status =
      bookingForHour?.user_id === this.currentUser?.id ? BookingStatus.bookedByYou : BookingStatus.bookedByAnotherUser;

    if (bookingForHour.is_waiting_for_approval) {
      status = BookingStatus.waitingForApproval;
    }

    if (bookingForHour?.blocked || !!adjacentBookingForHour) {
      status = BookingStatus.blocked;
    }

    return {
      hour,
      bookingId: bookingForHour?.id,
      percentage: Math.round(Math.abs(percentage)),
      maskPosition: Math.round(Math.abs(maskPosition)),
      hide: status === BookingStatus.waitingForApproval || status === BookingStatus.available,
      status,
    };
  }

  protected getBookingsForDevices(bookings: Booking[]): Booking[] {
    let locationAdjacentBookings: Booking[] = [];

    for (const booking of this.sameLocationDeviceBookings ?? []) {
      if (booking.device.block_adjacent_devices && booking.device.id !== this.device.id) {
        locationAdjacentBookings = [...locationAdjacentBookings, booking];
      }
    }

    return locationAdjacentBookings;
  }

  /**
   * Populate the hours and days for timeline/calendar
   *
   * @return void
   */
  private populateCalendar(): void {
    this.days = [];
    this.hours = [];

    const today = new Date(this.date);

    this.weekNumber = format(today, 'ww');

    for (let i = 0; i < this.amountOfDays; i++) {
      const day = add(startOfISOWeek(today), { days: i });
      const hours = [];

      /**
       * Check to see if there are other devices coupled to the same location
       */
      const locationAdjacentBookings = this.getBookingsForDevices(this.sameLocationDeviceBookings);
      const bookingsForDay: BookingHour[] = this.getBookingsForDay(
        this._bookings,
        format(day, DatesFormats.defaultDateReversed),
      );
      const blockedAdjacentBookings: BookingHour[] = this.getBookingsForDay(
        locationAdjacentBookings,
        format(day, DatesFormats.defaultDateReversed),
      );

      /**
       * Loop through all of the hours and see if a booking matches
       */
      for (let j = 0; j < this.amountOfHours; j++) {
        const bookingForHour: BookingHour[] = this.getBookingForHour(bookingsForDay, j);
        const adjacentBookingForHour: BookingHour[] = this.getBookingForHour(blockedAdjacentBookings, j);

        /**
         * If no booking for the current device is found but there is another device
         * with adjacent booking, assign it so if passes in the statement
         */
        let result: BookingHour[] = bookingForHour;

        if (!bookingForHour.length && !!adjacentBookingForHour.length) {
          result = [adjacentBookingForHour[0]];
        }

        let hour: CalendarHour[] = [];
        const availableBookingHour: CalendarHour = {
          hour: j,
          bookingId: '',
          percentage: 100,
          maskPosition: 0,
          hide: true,
          status: BookingStatus.available,
        };

        if (result.length) {
          hour = result.reduce(
            (bookingHourParts, currentBookingHour, currentIndex, bookingHours) => {
              const hourPart = this.createHourForCalendar(currentBookingHour, !!adjacentBookingForHour.length, j);

              bookingHourParts.currentPercentage += hourPart.percentage;
              bookingHourParts.hourParts.push(hourPart);

              if (currentIndex === bookingHours.length - 1 && bookingHourParts.currentPercentage < 100) {
                bookingHourParts.hourParts.push(availableBookingHour);
              }

              return bookingHourParts;
            },
            { currentPercentage: 0, hourParts: [] },
          ).hourParts;
        } else {
          hour.push(availableBookingHour);
        }

        hours.push({
          hour,
        });
      }

      this.days.push({
        dayName: format(day, 'EEE'),
        dayFormat: format(day, 'dd - MM - yyyy'),
        hours,
      });
    }

    for (let i = 0; i < this.amountOfHours; i++) {
      this.hours.push(i);
    }

    this.cdr.detectChanges();
  }
}
