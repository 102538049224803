import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '@src/app/features/booking/models/booking.model';
import { differenceInHours } from 'date-fns';

@Pipe({
  name: 'bookingDateDifference',
  standalone: true,
})
export class BookingDateDifference implements PipeTransform {
  public transform(booking: Booking, modifyHours: number): boolean {
    const today = new Date();
    const startDate = new Date(booking.period_start);

    return differenceInHours(startDate, today) >= modifyHours;
  }
}
