import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Layout } from '@core/enums/general/layout.enum';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';

@Component({
  selector: 'app-manage-detail-state',
  templateUrl: './manage-detail-state.component.html',
  styleUrls: ['./manage-detail-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CapturumSkeletonModule],
})
export class ManageDetailStateComponent {
  @Input() public loading = true;
  @Input() public styleClass?: string;
  @Input() public layout: string = Layout.default;
}
