import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SettingService } from '@capturum/complete';

export class FormUtils {
  /**
   * Mark a formgroup as touched
   *
   * @param formGroup: FormGroup
   * @return void
   */
  public static markAsTouched(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.markAsTouched(control);
      }
    });
  }

  /**
   * Mark a formcontrol as touched
   *
   * @param control: FormControl
   * @return void
   */
  public static markControlAsTouched(control: UntypedFormControl): void {
    control.markAsTouched({ onlySelf: true });
  }

  /**
   * Toggle visibility of the formly fields based on settings
   *
   * @param formlyFields: FormlyFieldConfig[]
   * @param settingService: SettingService
   * @return FormlyFieldConfig[]
   */
  public static applySettingsToFields(
    formlyFields: FormlyFieldConfig[],
    settingService: SettingService,
  ): FormlyFieldConfig[] {
    for (const field of formlyFields) {
      field.hide = field.props.setting && settingService.getValue(field.props.setting) === false;
    }

    return formlyFields;
  }

  public static getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    for (let i = 0, length = fields.length; i < length; i++) {
      const field = fields[i];

      if (field.key === key) {
        return field;
      }

      if (field.fieldGroup && !field.key) {
        const formlyFieldConfig = this.getField(key, field.fieldGroup);

        if (formlyFieldConfig) {
          return formlyFieldConfig;
        }
      }
    }
  }
}
