import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class HtmlMetaService {
  constructor(
    @Inject(DOCUMENT) private document,
    private metaService: Meta,
  ) {}

  public updateMetaDescription(content: string): void {
    this.metaService.updateTag({ name: 'description', content });
  }

  public updateMetaTitle(content: string): void {
    this.metaService.updateTag({ name: 'title', content });
  }

  public emptyMeta(): void {
    this.metaService.updateTag({ name: 'description', content: '' });
    this.metaService.updateTag({ name: 'title', content: '' });
  }

  public createCanonicalLink(url: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');

    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);

    this.deleteCanonicalLink();

    this.document.head.appendChild(link);
  }

  public deleteCanonicalLink(): void {
    const existingCanonicalLink = this.existingCanonicalLink();

    if (existingCanonicalLink) {
      this.document.head.removeChild(existingCanonicalLink);
    }
  }

  private existingCanonicalLink(): HTMLLinkElement {
    return this.document.head.querySelector('link[rel="canonical"]');
  }
}
