<div class="navigation-button" [ngClass]="navigationButton.styleClass">
  <div class="navigation-button__content">
    <cap-button
      styleClass="secondary"
      (onClick)="navigationButton?.previous?.callback()"
      [disabled]="navigationButton?.previous?.disabled | observablePipe | async"
      [icon]="navigationButton?.previous?.icon || 'fas fa-chevron-circle-left'"
      [iconPos]="navigationButton?.previous?.iconPos || 'left'"
      [label]="navigationButton?.previous?.label || 'button.previous' | translate">
    </cap-button>

    <div class="navigation-button__title">
      {{ navigationButton?.title | observablePipe | async }}
      <ng-content></ng-content>
    </div>

    <cap-button
      styleClass="secondary"
      (onClick)="navigationButton?.next?.callback()"
      [disabled]="navigationButton?.next?.disabled | observablePipe | async"
      [icon]="navigationButton?.next?.icon || 'fas fa-chevron-circle-right'"
      [iconPos]="navigationButton?.next?.iconPos || 'right'"
      [label]="navigationButton?.next?.label || 'button.next' | translate">
    </cap-button>
  </div>
</div>
