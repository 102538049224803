import { parseISO } from 'date-fns';
import { formatDate } from '@angular/common';

export function isoToDMY(date: string): string {
  return formatDate(date, 'dd-MM-yyyy', 'nl-NL', '+0000');
}

export function isoToYMD(date: string): string {
  return formatDate(date, 'yyyy-MM-dd', 'nl-NL', '+0000');
}

export function isoToHM(date: string): string {
  return formatDate(date, 'HH:mm', 'nl-NL', '+0000');
}

export function isoToDate(date: Date | string, resetTime = false, ignoreTimezone = false): Date {
  let dateObject: Date;

  if (date instanceof Date) {
    dateObject = date as Date;
  } else {
    if (ignoreTimezone && date.includes('+')) {
      // eslint-disable-next-line no-param-reassign
      date = date.substring(0, date.length - 5);
    }

    dateObject = parseISO(date as string);
  }

  if (resetTime) {
    dateObject.setHours(0, 0, 0, 0);
  }

  return dateObject;
}
