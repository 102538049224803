import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';

@Pipe({
  name: 'labelFromOptions',
  standalone: true,
})
export class LabelFromOptionsPipe implements PipeTransform {
  public transform(value: string, options: MapItem[]): string {
    return (
      options?.find((option) => {
        return option.value === value;
      })?.label ?? null
    );
  }
}
