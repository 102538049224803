import { NavigationButton } from '@core/models/navigation-button.model';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigateDirection, CapturumSharedModule } from '@capturum/ui/api';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, CapturumButtonModule, AsyncPipe, TranslateModule, CapturumSharedModule],
})
export class NavigationButtonComponent {
  @Input()
  public title: string;

  @Input()
  public navigationButton: NavigationButton;

  @Output()
  public navigate: EventEmitter<NavigateDirection> = new EventEmitter();

  public NavigateDirection: typeof NavigateDirection = NavigateDirection;
}
