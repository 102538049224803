import { Injectable } from '@angular/core';
import { ApiConfig, ApiHttpService as CapturumApiHttpService } from '@capturum/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class CustomApiHttpService extends CapturumApiHttpService {
  constructor(
    private configuration: ApiConfig,
    private httpClient: HttpClient,
    private authenticationService: AuthService,
  ) {
    super(configuration, httpClient, authenticationService);
  }

  /**
   * @inheritDoc
   */
  public getHeaders(customHeaders?: HttpHeaders): HttpHeaders {
    if (customHeaders) {
      return customHeaders;
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const token = this.authenticationService.getToken();

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    return new HttpHeaders(headers);
  }
}
