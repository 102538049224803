import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { IpPermission } from '@core/models/ip-permission.model';

@Injectable({
  providedIn: 'root',
})
export class IpPermissionService extends ApiService<IpPermission> {
  protected endpoint = 'public/is-allowed-to-login';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public getIpPermissionState(): Observable<IpPermission> {
    return this.apiHttp.get(`/${this.endpoint}`);
  }
}
