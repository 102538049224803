<div class="form-group" [class.has-error]="showError">
  @if (to.translateLabel && to.hideLabel !== true) {
    <label [attr.for]="id">
      {{ to.translateLabel | observablePipe | async }}
      @if (to.required && to.hideRequiredMarker !== true) {
        <span>*</span>
      }
      @if (to.labelIcon) {
        <i
          [class]="to.labelIcon.icon"
          [pTooltip]="to.labelIcon.tooltip | observablePipe | async"
          [tooltipPosition]="to.labelIcon.tooltipPosition || 'top'">
        </i>
      }
    </label>
  }

  <ng-template #fieldComponent></ng-template>

  @if (to.description) {
    <small class="form-text text-muted">{{ to.description | observablePipe | async }}</small>
  }
</div>
