export enum Favicon {
  radboud = 'FAVICON_RADBOUD',
  wageningen = 'FAVICON_WAGENINGEN',
}

export type AvailableFavicons = { [key in Favicon]: string };

export const favicons: AvailableFavicons = {
  FAVICON_RADBOUD: 'assets/images/radboud/favicon.ico',
  FAVICON_WAGENINGEN: 'assets/images/wageningen/favicon.ico',
};
