<p-sidebar
  [(visible)]="visible"
  styleClass="radboud-right-sidebar"
  position="right"
  [showCloseIcon]="false"
  (onHide)="hide()">
  <div class="radboud-right-sidebar__header">{{ title }} <i class="fas fa-times" (click)="hide()"></i></div>

  <div class="radboud-right-sidebar__content">
    @if (childComponentType) {
      <ng-template capDynamicDialogContent></ng-template>
    } @else {
      <ng-content></ng-content>
    }
  </div>

  @if (showButtons) {
    <div class="radboud-right-sidebar__footer">
      <cap-button styleClass="secondary" [label]="cancelButtonTranslationKey | translate" (click)="cancel()">
      </cap-button>
      <cap-button
        styleClass="success"
        [label]="submitButtonTranslationKey | translate"
        icon="fas fa-save"
        (click)="submit()"
        [disabled]="submitButtonDisabled">
      </cap-button>
    </div>
  }
</p-sidebar>
