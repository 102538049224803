import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';

@Component({
  template: `
    <p-inputMask
      [type]="to?.type || 'text'"
      [class]="to?.styleClass"
      [placeholder]="to?.translatePlaceholder || to?.placeholder | observablePipe | async"
      [formlyAttributes]="field"
      [formControl]="formControl"
      [required]="to?.required || false | observablePipe | async"
      [class.ng-dirty]="showError"
      [mask]="to?.mask"
      [characterPattern]="to?.characterPattern">
    </p-inputMask>
  `,
  standalone: true,
  imports: [InputMaskModule, FormlyModule, FormsModule, ReactiveFormsModule, AsyncPipe, CapturumSharedModule],
})
export class InputMaskFormlyTypeComponent extends FieldType {}
