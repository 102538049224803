import { ListOptions } from '@capturum/api';

export function getLikeValue(listOptions: ListOptions): ListOptions {
  const adjustedOptions: ListOptions = { ...listOptions };

  if (adjustedOptions.filters && adjustedOptions.filters.length > 0) {
    adjustedOptions.filters.forEach((filter) => {
      if (filter.operator === 'like') {
        filter.value = `%${filter.value}%`;
      }
    });
  }

  return adjustedOptions;
}
