import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Device } from '@features/device/models/device.model';
import { DeviceCardType } from '@core/enums/device/device-card-type.enum';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { CapturumDialogService, CapturumSharedModule } from '@capturum/ui/api';
import { DeviceStatus } from '@core/enums/device/device-status.enum';
import { Entities } from '@core/enums/general/entities.enum';
import { LoginModalComponent } from '@shared/components/login-modal/login-modal.component';
import { StripHtmlPipe } from '@shared/pipes/strip-html.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { DeviceImagePipe } from '../../pipes/device-image.pipe';
import { AssetsPipe } from '@shared/pipes/assets.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';
import { NgTemplateOutlet } from '@angular/common';
import { DeviceShowMessagePipe } from '@shared/pipes/device-show-message.pipe';
import { SettingService } from '@capturum/complete';
import { Category } from '@src/app/features/category/models/category.model';
import { AuthService } from '@capturum/auth';
import { User } from '@features/manage-user/models/user.model';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    AssetsPipe,
    DeviceImagePipe,
    DefaultPipe,
    StripHtmlPipe,
    DeviceShowMessagePipe,
  ],
})
export class DeviceCardComponent {
  @Input() public device: Device;
  @Input() public deviceCardType: string = DeviceCardType.expanded;
  @Input() public isAuthenticated = false;
  @Input()
  public category: Category;

  @Input()
  public useDeviceId: boolean;

  public cardTypes: typeof DeviceCardType = DeviceCardType;
  public routes: typeof AppRoutes = AppRoutes;
  public deviceStatuses: typeof DeviceStatus = DeviceStatus;
  public showMessage = this.settingService.getValueString('radboud.show_device_message');
  public allowBookingOfDevicesWithAnyStatus = this.settingService.getValue(
    'radboud.allow_booking_of_devices_with_any_status',
  );

  public currentUser = this.authService.getUser() as unknown as User;

  constructor(
    public settingService: SettingService,
    public authService: AuthService,
    private router: Router,
    private dialogService: CapturumDialogService,
    private route: ActivatedRoute,
  ) {}

  /**
   * Open device details modal
   *
   * @param deviceName: string
   * @return void
   */
  public openDeviceDetails(device: Device): void {
    const deviceName = this.useDeviceId ? device.id : device.short_name;
    const category = this.category ? `${AppRoutes.category}/${encodeURIComponent(this.category.short_name)}/` : '';
    const url = `domain/${device.domain?.name}/${category}${AppRoutes.device}/${encodeURIComponent(deviceName)}`;

    this.router.navigate([url]);
  }

  /**
   * Route to edit device page
   *
   * @param device: Device
   * @return void
   */
  public editDevice(device: Device): void {
    this.router.navigate([`${AppRoutes.manage}/${Entities.device}/${device.id}`]);
  }

  /**
   * Route to booking page for device
   *
   * @param deviceId: string
   * @return void
   */
  public routeToBooking({ id }: Device): void {
    this.router.navigate([`${AppRoutes.booking}/${id}`]);
  }

  /**
   * Route to login with redirect uri
   *
   * @param deviceId?: string
   * @return void
   */
  public routeToLogin(deviceId?: string): void {
    this.router.navigate([AppRoutes.authLogin], {
      queryParams: {
        redirectUrl: deviceId ? `${AppRoutes.booking}/${deviceId}` : window.location.pathname,
      },
    });
  }

  /**
   * Open login modal
   *
   * @param deviceId: string
   * @return void
   */
  public openLoginModal(deviceId?: string): void {
    this.dialogService.open(LoginModalComponent, {
      styleClass: 'radboud-dialog radboud-login-dialog',
      autoZIndex: false,
      dismissableMask: true,
      showHeader: false,
      modal: true,
      data: {
        deviceId,
      },
    });
  }
}
