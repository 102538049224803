import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, Event, RoutesRecognized } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteStateService {
  private previousUrl$: BehaviorSubject<string | null>;

  constructor(private router: Router) {
    this.previousUrl$ = new BehaviorSubject<string | null>(null);
  }

  /**
   * Return the previous url as Observable value
   *
   * @return Observable<string | null>
   */
  public getPreviousUrl(): Observable<string | null> {
    return this.previousUrl$.asObservable();
  }

  /**
   * Return the previous url as string value
   *
   * @return string | null
   */
  public getPreviousUrlAsString(): string | null {
    const previousUrlEncoded = this.previousUrl$.getValue();

    return previousUrlEncoded ? decodeURIComponent(previousUrlEncoded) : null;
  }

  /**
   * Listen for route changes, and update subject accordingly
   *
   * @return void
   */
  public listenForRouteChanges(): void {
    this.router.events
      .pipe(
        filter((event: Event) => {
          return event instanceof RoutesRecognized;
        }),
        pairwise(),
      )
      .subscribe((events: Event[]) => {
        const previousUrl = (events[0] as RoutesRecognized).url;

        this.previousUrl$.next(previousUrl);
      });
  }
}
