import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@capturum/auth';
import { CustomPermissionService } from '@core/services/custom-permission.service';

@Injectable()
export class PermissionResolver {
  constructor(
    private permissionService: CustomPermissionService,
    private authService: AuthService,
  ) {}

  /**
   * Resolve the permissions
   *
   * @param route: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   * @return Observable<boolean>
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissionService.loadPermissions(this.authService.isAuthenticated());
  }
}
