import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  template: `
    <div class="d-flex align-items-center">
      <p-inputNumber
        [formControl]="formControl"
        [class]="to.styleClass"
        [placeholder]="to.translatePlaceholder || to.placeholder | observablePipe | async"
        [formlyAttributes]="field"
        [class.ng-dirty]="showError"
        mode="currency"
        currency="EUR"
        locale="nl-NL">
      </p-inputNumber>

      @if (to.rightText) {
        <span class="ml-3">
          {{ to.rightText }}
        </span>
      }
    </div>
  `,
  standalone: true,
  imports: [InputNumberModule, FormsModule, ReactiveFormsModule, FormlyModule, AsyncPipe, CapturumSharedModule],
})
export class CurrencyInputFormlyTypeComponent extends FieldType {}
