<div class="{{ mode }}-form-card">
  <cap-card>
    <ng-template capTemplate="header">
      <div class="radboud-card-header">
        <span class="d-flex">
          <i [class]="icon"></i>

          {{ title }}

          @if (tooltip?.tooltipLabel) {
            <i [class]="tooltip.tooltipIcon" class="tooltip-icon" [pTooltip]="tooltip.tooltipLabel | translate"></i>
          }
        </span>

        <ng-content select="[buttons]"></ng-content>
      </div>
    </ng-template>

    <ng-template capTemplate="content">
      <div [class.form-card__form--disabled]="form && form.disabled">
        <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"> </formly-form>
      </div>

      <ng-content></ng-content>
    </ng-template>
  </cap-card>
</div>
