import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { NgIf, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  template: `
    <div class="d-flex align-items-center">
      <p-inputNumber
        [formControl]="formControl"
        [class]="props.styleClass"
        [placeholder]="props.translatePlaceholder || props.placeholder | observablePipe | async"
        [formlyAttributes]="field"
        [class.ng-dirty]="showError"
        [min]="props.min"
        [minFractionDigits]="2"
        [maxFractionDigits]="2"
        mode="decimal"
        locale="nl-NL">
      </p-inputNumber>

      <span *ngIf="props.rightText" class="ml-3">
        {{ props.rightText }}
      </span>
    </div>
  `,
  standalone: true,
  imports: [InputNumberModule, FormsModule, ReactiveFormsModule, FormlyModule, NgIf, AsyncPipe, CapturumSharedModule],
})
export class DecimalInputFormlyTypeComponent extends FieldType {}
