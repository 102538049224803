import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { getLikeValue } from '@core/helpers/like-value.helper';

@Injectable({
  providedIn: 'root',
})
export class ResourceApiService<M> extends ApiService<M> {
  /**
   * Get the scoped index
   *
   * @param options: ListOptions
   * @return Observable<ApiIndexResult<T>>
   */
  public scopedManageIndex<T = M>(options?: ListOptions): Observable<ApiIndexResult<T>> {
    return this.apiHttp.get<ApiIndexResult<T>>(`/manage/${this.endpoint}` + this.getOptionsQuery(options));
  }

  /**
   * @param type
   * @param listOptions
   */
  public export(type: string, listOptions?: ListOptions): Observable<Blob> {
    const adjustedOptions = getLikeValue(listOptions);

    return this.apiHttp.getBlob(`/export/${this.endpoint}/${type}${this.getOptionsQuery(adjustedOptions)}`, type);
  }

  /**
   * Export Job
   *
   * @return Observable<Blob>
   */
  public exportByJob(type: string, listOptions?: ListOptions): Observable<{ export_id: string }> {
    const options = this.getOptionsQuery({
      search: listOptions.search,
      parameters: listOptions.parameters,
      filters: listOptions.filters,
    });

    return this.apiHttp.get(`/export-job/${this.endpoint}/${type}${options}`);
  }

  /**
   * Export Job Download
   *
   * @return Observable<Blob>
   */
  public exportJobDownload(type: string, exportId: string): Observable<Blob> {
    return this.apiHttp.getBlob(`/export-job-download/${exportId}`, type);
  }
}
