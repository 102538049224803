import { Injectable } from '@angular/core';
import { SettingIndexedDbModel, SettingService } from '@capturum/complete';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ListOptions } from '@capturum/api';
import { getLikeValue } from '@core/helpers/like-value.helper';

@Injectable({
  providedIn: 'root',
})
export class CustomSettingService extends SettingService {
  /**
   * Load all the settings and put in IndexedDB
   *
   * @param authenticated: boolean
   * @return Observable<boolean>
   */
  public loadSettings(authenticated: boolean): Observable<boolean> {
    return new Observable((observer) => {
      const uri: string = authenticated ? '/role' : '/public';

      this.apiHttp
        .get(`${uri}/setting`)
        .pipe(
          map((response: any) => {
            return response.data;
          }),
          map((settings) => {
            const result = [];

            for (const key in settings) {
              if (settings.hasOwnProperty(key)) {
                result.push({ id: key, value: settings[key] });
              }
            }

            return result;
          }),
          switchMap((settings) => {
            return combineLatest([
              from(SettingIndexedDbModel.query().clear()),
              from(SettingIndexedDbModel.query().bulkAdd(settings)),
              from(SettingIndexedDbModel.loadSettings()),
            ]);
          }),
          tap(() => {
            this.setGlobalStyles();
            this.settingsChanged$.next();
          }),
        )
        .subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          },
        );
    });
  }

  /**
   * @inheritDoc
   */
  public setGlobalStyles(): void {
    const colorSettings = SettingIndexedDbModel.settings.filter((setting) => {
      return (setting.id as string).includes('color');
    });

    for (const setting of colorSettings) {
      let key = setting.id as string;

      if (key.includes('general.tenant_')) {
        key = key.split('general.tenant_')[1];
      }

      const value = setting.value as string;
      const trimmedHexValue = value.slice(1, value.length);

      document.documentElement.style.setProperty(
        `--cap-${key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase()}`,
        value,
      );

      document.documentElement.style.setProperty(
        `--${key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase()}`,
        value,
      );

      document.documentElement.style.setProperty(
        `--cap-${key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase()}-rgb`,
        this.hexToRgb(trimmedHexValue),
      );

      document.documentElement.style.setProperty(
        `--${key
          .replace(/([a-z])([A-Z])/g, '$1-$2')
          .replace(/[\s_]+/g, '-')
          .toLowerCase()}-rgb`,
        this.hexToRgb(trimmedHexValue),
      );
    }
  }

  /**
   * Export
   *
   * @return Observable<Blob>
   */
  public export(type: string, listOptions?: ListOptions): Observable<Blob> {
    const adjustedOptions = getLikeValue(listOptions);

    return this.apiHttp.getBlob(`/export/${this.endpoint}/${type}${this.getOptionsQuery(adjustedOptions)}`, type);
  }

  /**
   * Convert hex decimal string to RGB
   *
   * @param hex: string
   * @return string
   */
  private hexToRgb(hex: string): string {
    const bigint = parseInt(hex, 16);
    // tslint:disable-next-line:no-bitwise
    const r = (bigint >> 16) & 255;
    // tslint:disable-next-line:no-bitwise
    const g = (bigint >> 8) & 255;
    // tslint:disable-next-line:no-bitwise
    const b = bigint & 255;

    return [r, g, b].join();
  }
}
