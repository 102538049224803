import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumCalendarModule } from '@capturum/ui/calendar';

@Component({
  template: `
    <cap-calendar
      [formlyAttributes]="field"
      [formControl]="formControl"
      [defaultDate]="to.defaultDate || null"
      [selectionMode]="to.selectionMode || 'single'"
      [style]="to.style || null"
      [inputStyle]="to.inputStyle || null"
      [inputId]="field.key"
      [name]="field.key"
      [placeholder]="to.translatePlaceholder || to.placeholder | observablePipe | async"
      [disabled]="to.disabled"
      [dateFormat]="to.dateFormat || 'dd-mm-yy'"
      [inline]="to.inline || false"
      [showOtherMonths]="to.showOtherMonths || true"
      [selectOtherMonths]="to.selectOtherMonths"
      [showIcon]="to.showIcon || true"
      [showOnFocus]="to.showOnFocus || true"
      [icon]="to.icon || 'pi pi-calendar'"
      [appendTo]="to.appendTo || null"
      [readonlyInput]="to.readOnlyInput || null"
      [shortYearCutoff]="to.shortYearCutoff || '+10'"
      [minDate]="to.minDate || null"
      [maxDate]="to.maxDate || null"
      [disabledDates]="to.disabledDates || null"
      [disabledDays]="to.disabledDays || null"
      [monthNavigator]="to.monthNavigator || false"
      [yearNavigator]="to.yearNavigator || false"
      [yearRange]="to.yearRange || false"
      [showTime]="to.showTime || false"
      [hourFormat]="to.hourFormat || '24'"
      [timeOnly]="to.timeOnly || false"
      [dataType]="to.dataType || 'date'"
      [required]="to.required || false"
      [tabIndex]="to.tabindex || null"
      [showSeconds]="to.showSeconds"
      [stepHour]="to.stepHour || 1"
      [stepMinute]="to.stepMinute || 1"
      [stepSecond]="to.stepSecond || 1"
      [maxDateCount]="to.maxDateCount || null"
      [showButtonBar]="to.showButtonBar || false"
      [todayButtonStyleClass]="to.todayButtonStyleClass || 'ui-secondary-button'"
      [clearButtonStyleClass]="to.clearButtonStyleClass || 'ui-secondary-button'"
      [panelStyleClass]="to.panelStyleClass || null"
      [panelStyle]="to.panelStyle || null"
      [keepInvalid]="to.keepInvalid || false"
      [hideOnDateTimeSelect]="to.hideOnDateTimeSelect || false"
      [numberOfMonths]="to.numberOfMonths || 1"
      [view]="to.view || 'date'"
      [touchUI]="to.touchUI || false"
      [showTransitionOptions]="to.showTransitionOptions || '225ms ease-out'"
      [hideTransitionOptions]="to.hideTransitionOptions || '195ms ease-in'"
      [inputStyleClass]="to.inputStyleClass || null"
      (select)="to.onSelect ? to.onSelect($event) : null"
      (blur)="to.onBlur ? to.onBlur($event) : null"
      (focus)="to.onFocus ? to.onFocus($event) : null"
      (close)="to.onClose ? to.onClose($event) : null"
      (input)="to.onInput ? to.onInput($event) : null"
      (todayClick)="to.onTodayClick ? to.onTodayClick($event) : null"
      (clearClick)="to.onClearClick ? to.onClearClick($event) : null"
      (monthChange)="to.onMonthChange ? to.onMonthChange($event) : null"
      (yearChange)="to.onYearChange ? to.onYearChange($event) : null">
    </cap-calendar>
  `,
  standalone: true,
  imports: [CapturumCalendarModule, FormlyModule, FormsModule, ReactiveFormsModule, AsyncPipe, CapturumSharedModule],
})
export class CalendarFormlyTypeComponent extends FieldType {
  public defaultOptions = {
    templateOptions: {
      filter: true,
      filterBy: 'label',
      appendTo: null,
      scrollHeight: '200px',
      overlayVisible: false,
      dataKey: 'value',
      defaultLabel: 'Choose',
      onChange: null,
    },
  };
}
