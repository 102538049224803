import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'default',
  standalone: true,
})
export class DefaultPipe implements PipeTransform {
  public transform(value: any, defaultValue = '-'): any {
    const isEmptyValue = typeof value !== 'number' && !value;
    const isEmptyArray = Array.isArray(value) && value.length === 0;

    return isEmptyValue || isEmptyArray ? defaultValue : value;
  }
}
