import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogStateService {
  private isDialogOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Get the dialog state as observable
   *
   * @return Observable<boolean>
   */
  public getDialogState(): Observable<boolean> {
    return this.isDialogOpen$.asObservable();
  }

  /**
   * Set the dialog state
   *
   * @param state: boolean
   * @return void
   */
  public setDialogState(state: boolean): void {
    this.isDialogOpen$.next(state);
  }
}
