import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { CompleteConfig } from '@capturum/complete';
import { LoadingService } from '@capturum/ui/loader';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataResolver } from '@core/resolvers/base-data.resolver';
import { ModuleResolver } from '@core/resolvers/module.resolver';
import { SettingResolver } from '@core/resolvers/setting.resolver';
import { TranslationResolver } from '@core/resolvers/translation.resolver';
import { PermissionResolver } from '@core/resolvers/permission.resolver';
import { AuthService } from '@capturum/auth';

@Injectable()
export class CompleteResolver {
  constructor(
    private baseDataResolver: BaseDataResolver,
    private moduleResolver: ModuleResolver,
    private settingResolver: SettingResolver,
    private translationResolver: TranslationResolver,
    private permissionResolver: PermissionResolver,
    private translateService: TranslateService,
    private loadingService: LoadingService,
    private coreConfig: CompleteConfig,
    private authService: AuthService,
  ) {}

  /**
   * Resolve the complete data
   *
   * @param route: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   * @return Observable<boolean>
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let completeResolvers: Observable<boolean>[];
    const publicResolvers = [
      this.translationResolver.resolve(route, state),
      this.settingResolver.resolve(route, state),
      this.moduleResolver.resolve(route, state),
      this.baseDataResolver.resolve(route, state),
    ];
    const authResolvers = [this.permissionResolver.resolve(route, state)];

    this.loadingService.toggleLoading(
      true,
      this.coreConfig.useTranslatedLoaderText
        ? this.translateService.instant(this.coreConfig.loaderText)
        : this.coreConfig.loaderText,
      this.coreConfig.loaderImage,
      'complete-resolver-loader',
    );

    return new Observable((observer) => {
      this.authService
        .getAuthenticationState()
        .pipe(
          first(),
          switchMap((authenticated) => {
            completeResolvers = authenticated ? [...publicResolvers, ...authResolvers] : publicResolvers;

            return forkJoin(completeResolvers);
          }),
          catchError((error) => {
            this.loadingService.hideLoader();
            observer.error(error);

            return throwError(() => {
              return error;
            });
          }),
        )
        .subscribe(() => {
          this.loadingService.hideLoader();
          observer.next(true);
          observer.complete();
        });
    });
  }
}
