import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { Router } from '@angular/router';
import { CustomLoginService } from '@core/services/custom-login.service';
import { AssetsPipe } from '@shared/pipes/assets.pipe';
import { CapturumSharedModule } from '@capturum/ui/api';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CapturumButtonModule, TranslateModule, CapturumSharedModule, AssetsPipe],
})
export class LoginModalComponent {
  public config: any;

  constructor(
    public loginService: CustomLoginService,
    public ref: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private router: Router,
  ) {
    this.config = this.dialogConfig.data;
  }

  /**
   * Close the dialog
   *
   * @return void
   */
  public close(): void {
    this.ref.close();
  }

  /**
   * Route to login with redirect uri
   *
   * @return void
   */
  public routeToLogin(): void {
    this.router.navigate([AppRoutes.authLogin], {
      queryParams: {
        redirectUrl: this.config.deviceId ? `${AppRoutes.booking}/${this.config.deviceId}` : window.location.pathname,
      },
    });

    this.ref.close();
  }
}
