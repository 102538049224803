import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, UnauthenticatedGuard } from '@capturum/auth';
import { IndexedDbGuard } from '@capturum/complete';
import { loginRoutes } from '@capturum/login';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { IpPermissionGuard } from '@core/guards/ip-permission.guard';
import { RuPermissionGuardService } from '@core/guards/ru-permission-guard.service';
import { UserCompleteGuard } from '@core/guards/user-complete.guard';
import { BasicLayoutComponent } from '@core/layout/basic-layout/basic-layout.component';
import { CompleteResolver } from '@core/resolvers/complete.resolver';
import { PublicTranslationResolver } from '@core/resolvers/public-translation.resolver';
import { SettingResolver } from '@core/resolvers/setting.resolver';
import { SurfconextLoginCallbackComponent } from '@features/auth/pages/surfconext-login-callback/surfconext-login-callback.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [IndexedDbGuard, UserCompleteGuard],
    canActivateChild: [UserCompleteGuard],
    resolve: {
      complete: CompleteResolver,
    },
    children: [
      {
        path: '',
        redirectTo: AppRoutes.portal,
        pathMatch: 'full',
      },
      {
        path: '',
        component: BasicLayoutComponent,
        children: [
          {
            path: '',
            loadChildren: () => {
              return import('./features/domain/domain.module').then((m) => {
                return m.DomainModule;
              });
            },
          },
          {
            path: AppRoutes.searchForDevices,
            loadChildren: () => {
              return import('./features/search-for-devices/routes').then((m) => {
                return m.default;
              });
            },
          },
          {
            path: AppRoutes.globalSearch,
            loadChildren: () => {
              return import('./features/global-search/global-search.module').then((m) => {
                return m.GlobalSearchModule;
              });
            },
          },
          {
            path: AppRoutes.device,
            loadChildren: () => {
              return import('./features/device/device.module').then((m) => {
                return m.DeviceModule;
              });
            },
          },
          {
            path: AppRoutes.profile,
            canActivate: [AuthGuard],
            loadChildren: () => {
              return import('./features/profile/profile.module').then((m) => {
                return m.ProfileModule;
              });
            },
          },
          {
            path: AppRoutes.booking,
            canActivate: [AuthGuard],
            loadChildren: () => {
              return import('./features/booking/booking.module').then((m) => {
                return m.BookingModule;
              });
            },
          },
          {
            path: AppRoutes.userBooking,
            canActivate: [AuthGuard],
            loadChildren: () => {
              return import('./features/my-bookings/my-bookings.module').then((m) => {
                return m.MyBookingsModule;
              });
            },
          },
          {
            path: AppRoutes.manage,
            canActivate: [AuthGuard, RuPermissionGuardService],
            children: [
              {
                path: '',
                loadChildren: () => {
                  return import('./features/manage/manage.module').then((m) => {
                    return m.ManageModule;
                  });
                },
                data: {
                  permissions: {
                    only: [
                      'file.manage',
                      'locale.show',
                      'tenant.show.self',
                      'user.show.self',
                      'radboud.booking.show',
                      'radboud.booking.manage',
                      'radboud.device.show',
                      'radboud.device.manage',
                      'radboud.device-group.show',
                      'radboud.device-group.manage',
                      'radboud.device-group.manage-admin',
                      'radboud.booking-approval.show',
                      'radboud.booking-approval.manage',
                      'radboud.monster-booking.show',
                      'radboud.monster-booking.manage',
                      'radboud.search-tile-category.show',
                      'radboud.search-tile-category.manage',
                    ],
                    redirectTo: AppRoutes.portal,
                  },
                },
              },
              {
                path: AppRoutes.domain,
                loadChildren: () => {
                  return import('./features/manage-domain/manage-domain.module').then((m) => {
                    return m.ManageDomainModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.domain.show', 'radboud.domain.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.location,
                loadChildren: () => {
                  return import('./features/manage-location/manage-location.module').then((m) => {
                    return m.ManageLocationModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.location.show', 'radboud.location.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.device,
                loadChildren: () => {
                  return import('./features/manage-device/manage-device.module').then((m) => {
                    return m.ManageDeviceModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.device.show', 'radboud.device.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.category,
                loadChildren: () => {
                  return import('./features/manage-category/manage-category.module').then((m) => {
                    return m.ManageCategoryModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.category.show', 'radboud.category.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.institute,
                loadChildren: () => {
                  return import('./features/manage-institute/manage-institute.module').then((m) => {
                    return m.ManageInstituteModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.institute.show', 'radboud.institute.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.department,
                loadChildren: () => {
                  return import('./features/manage-department/manage-department.module').then((m) => {
                    return m.ManageDepartmentModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.department.show', 'radboud.department.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.costplace,
                loadChildren: () => {
                  return import('./features/manage-costplace/manage-costplace.module').then((m) => {
                    return m.ManageCostplaceModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.costplace.show', 'radboud.costplace.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.project,
                loadChildren: () => {
                  return import('./features/manage-project/manage-project.module').then((m) => {
                    return m.ManageProjectModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.project.show', 'radboud.project.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.company,
                loadChildren: () => {
                  return import('./features/manage-company/manage-company.module').then((m) => {
                    return m.ManageCompanyModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.company.show', 'radboud.company.manage'],
                  },
                  redirectTo: AppRoutes.manage,
                },
              },
              {
                path: AppRoutes.info,
                loadChildren: () => {
                  return import('./features/manage-info/manage-info.module').then((m) => {
                    return m.ManageInfoModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.info.show', 'radboud.info.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.reference,
                loadChildren: () => {
                  return import('./features/manage-reference/manage-reference.module').then((m) => {
                    return m.ManageReferenceModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.reference.show', 'radboud.reference.manage'],
                  },
                  redirectTo: AppRoutes.manage,
                },
              },
              {
                path: AppRoutes.user,
                loadChildren: () => {
                  return import('./features/manage-user/manage-user.module').then((m) => {
                    return m.ManageUserModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.user.show', 'radboud.user.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.additionalContacts,
                loadChildren: () => {
                  return import('./features/manage-additional-contacts/manage-additional-contacts.module').then((m) => {
                    return m.ManageAdditionalContactsModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.additional-contact.show', 'radboud.additional-contact.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.sendMail,
                loadChildren: () => {
                  return import('./features/manage-send-mail/manage-send-mail.module').then((m) => {
                    return m.ManageSendMailModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.send-mail.show', 'radboud.send-mail.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.deviceGroup,
                loadChildren: () => {
                  return import('./features/manage-device-group/manage-device-group.module').then((m) => {
                    return m.ManageDeviceGroupModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.device-group.show', 'radboud.device-group.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.report,
                loadChildren: () => {
                  return import('./features/manage-report/manage-report.module').then((m) => {
                    return m.ManageReportModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.report.show', 'radboud.report.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.bookingApproval,
                loadChildren: () => {
                  return import('./features/manage-booking-approval/manage-booking-approval.module').then((m) => {
                    return m.ManageBookingApprovalModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.booking-approval.show', 'radboud.booking-approval.manage'],
                  },
                  redirectTo: AppRoutes.manage,
                },
              },
              {
                path: AppRoutes.monsterBooking,
                loadChildren: () => {
                  return import('./features/manage-monster-booking/manage-monster-booking.module').then((m) => {
                    return m.ManageMonsterBookingModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.monster-booking.show', 'radboud.monster-booking.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.setting,
                loadChildren: () => {
                  return import('./features/manage-setting/manage-setting.module').then((m) => {
                    return m.ManageSettingModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['superuser.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.translation,
                loadChildren: () => {
                  return import('./features/manage-translation/manage-translation.module').then((m) => {
                    return m.ManageTranslationModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['superuser.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.timeslot,
                loadChildren: () => {
                  return import('./features/manage-timeslot/manage-timeslot.module').then((m) => {
                    return m.ManageTimeslotModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['radboud.timeslot.show', 'radboud.timeslot.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.role,
                loadChildren: () => {
                  return import('./features/role/role.module').then((m) => {
                    return m.RoleModule;
                  });
                },
                data: {
                  permissions: {
                    only: ['superuser.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
              {
                path: AppRoutes.searchTileCategory,
                loadChildren: async () => {
                  const module = await import('./features/manage-search-tile-category/routes');

                  return module.default;
                },
                data: {
                  permissions: {
                    only: ['radboud.search-tile-category.show', 'radboud.search-tile-category.manage'],
                    redirectTo: AppRoutes.manage,
                  },
                },
              },
            ],
            data: {
              permissions: {
                only: [
                  'file.manage',
                  'locale.show',
                  'tenant.show.self',
                  'user.show.self',
                  'radboud.booking.show',
                  'radboud.booking.manage',
                  'radboud.device.show',
                  'radboud.device.manage',
                  'radboud.device-group.show',
                  'radboud.device-group.manage',
                  'radboud.device-group.manage-admin',
                  'radboud.booking-approval.show',
                  'radboud.booking-approval.manage',
                  'radboud.monster-booking.show',
                  'radboud.monster-booking.manage',
                ],
                redirectTo: AppRoutes.portal,
              },
            },
          },
        ],
      },
    ],
  },
  {
    ...loginRoutes[0],
    children: [...loginRoutes[0].children],
    canActivate: [IndexedDbGuard, UnauthenticatedGuard, IpPermissionGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
      settings: SettingResolver,
    },
  },
  {
    path: AppRoutes.authSurfConextLogin,
    component: SurfconextLoginCallbackComponent,
    canActivate: [IndexedDbGuard, UnauthenticatedGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
      settings: SettingResolver,
    },
  },
  {
    path: '**',
    redirectTo: AppRoutes.portal,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
