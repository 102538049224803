import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@features/manage-user/models/user.model';

@Pipe({
  name: 'objectFullName',
  standalone: true,
})
export class ObjectFullName implements PipeTransform {
  /**
   * Return the full name by combining all name parts
   *
   * @param user: User
   * @return string
   */
  public transform(user: User): string {
    const userSegments = [user.first_name, user.middle_name, user.last_name];

    return userSegments.filter(Boolean).join(' ');
  }
}
