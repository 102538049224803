import { Injectable } from '@angular/core';
import { DeviceGroup } from '../models/device-group.model';
import { ResourceApiService } from '@core/services/resource-api.service';
import { ApiHttpService, ApiIndexResult, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { responseData } from '@src/app/core/utils/converter.utils';
import { MapItem } from '@capturum/ui/api';

@Injectable({
  providedIn: 'root',
})
export class DeviceGroupService extends ResourceApiService<DeviceGroup> {
  protected endpoint = 'device_group';
  public scopedUserId: string;

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  /**
   * Get the scoped index
   *
   * @param options: ListOptions
   * @return Observable<ApiIndexResult<T>>
   */
  public scopedManageIndex<DeviceGroup>(options?: ListOptions): Observable<ApiIndexResult<DeviceGroup>> {
    let endpoint = `/manage/${this.endpoint}`;

    if (this.scopedUserId) {
      endpoint += `/${this.scopedUserId}`;
    }

    return this.apiHttp.get<ApiIndexResult<DeviceGroup>>(endpoint + this.getOptionsQuery(options));
  }

  /**
   * Get all emails of users in the device group
   *
   * @param projectId: string
   * @return Observable<string[]>
   */
  public getUserEmails(id: string): Observable<string[]> {
    return this.apiHttp.get(`/manage/${this.endpoint}/${id}/user_emails`);
  }

  public getUserListByRole(userRoleId: string, domainId?: string): Observable<MapItem[]> {
    return this.apiHttp.get(`/${this.endpoint}/users/list/${userRoleId}/${domainId ?? ''}`).pipe(responseData);
  }
}
