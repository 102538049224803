import { Injectable } from '@angular/core';
import {
  BaseDataApiModel,
  BaseDataIndexedDbModel,
  BaseDataService,
  BaseDataValueIndexedDbModel,
} from '@capturum/complete';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ListOptions } from '@capturum/api';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomBaseDataService extends BaseDataService {
  /**
   * Load the base data
   *
   * @param authenticated: boolean
   * @return Observable<boolean>
   */
  public loadBaseDataValues(authenticated: boolean): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const uri = authenticated ? '/role' : '/public';

      combineLatest([this.loadBaseDataValuesByRole(uri), this.loadNestedBaseDataValues(authenticated)]).subscribe(
        () => {
          observer.next(true);
          observer.complete();
        },
        (error) => {
          observer.error(error);
          observer.complete();
        },
      );
    });
  }

  /**
   * Load Base Data Values and put in IndexedDB
   *
   * @param uri: string
   * @return Observable<boolean>
   */
  public loadBaseDataValuesByRole(uri: string): Observable<boolean> {
    return this.apiHttp.get(`${uri}/base-data`).pipe(
      map((response: { data: BaseDataApiModel[] }) => {
        return response.data.map((baseDataValue) => {
          return this.transformBaseDataValue(baseDataValue);
        });
      }),
      switchMap((baseDataValues) => {
        return combineLatest([
          from(BaseDataValueIndexedDbModel.query().clear()),
          from(BaseDataValueIndexedDbModel.query().bulkPut(baseDataValues)),
        ]).pipe(
          map(() => {
            return true;
          }),
        );
      }),
    );
  }

  /**
   * Load the nested Base Data Values and put in IndexedDB
   *
   * @param authenticated: boolean
   * @return Observable<boolean>
   */
  public loadNestedBaseDataValues(authenticated: boolean): Observable<boolean> {
    const options: ListOptions = { include: ['properties', 'values', 'values.propertyValues'], perPage: 10000 };

    return this.apiHttp.get(`${!authenticated ? '/public' : ''}/base-data-key${this.getOptionsQuery(options)}`).pipe(
      map((response: { data: BaseDataApiModel[] }) => {
        return response.data.map((baseDataKey) => {
          return this.transformBaseDataKey(baseDataKey);
        });
      }),
      switchMap((baseDataValues) => {
        return combineLatest([
          from(BaseDataIndexedDbModel.query().clear()),
          from(BaseDataIndexedDbModel.query().bulkPut(baseDataValues)),
        ]).pipe(
          map(() => {
            return true;
          }),
        );
      }),
    );
  }
}
