import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'sort',
  standalone: true,
})
export class ArraySortPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(array: any, field: string, sortDirection: 'asc' | 'desc' = 'asc', translate = false): any[] {
    let arrayField: string;
    let sortedArray: Array<any>;

    if (!Array.isArray(array)) {
      return;
    }

    if (translate) {
      const translationKeyField = field;

      arrayField = 'translatedField';
      sortedArray = array.map((item) => {
        item['translatedField'] = this.translateService.instant(item[translationKeyField]);

        return item;
      });
    }

    sortedArray.sort((a: any, b: any) => {
      let fieldA = a[arrayField];
      let fieldB = b[arrayField];

      if (typeof fieldA === 'string') {
        fieldA = fieldA.toLowerCase();
      }

      if (typeof fieldB === 'string') {
        fieldB = fieldB.toLowerCase();
      }

      if (fieldA < fieldB) {
        return sortDirection === 'asc' ? -1 : 1;
      } else if (fieldA > fieldB) {
        return sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });

    return sortedArray;
  }
}
