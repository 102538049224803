import { Pipe, PipeTransform } from '@angular/core';
import { Booking } from '@features/booking/models/booking.model';
import { format, isEqual } from 'date-fns';
import { isoToDate } from '@core/utils/date.utils';

@Pipe({
  name: 'bookingDate',
  standalone: true,
})
export class BookingDatePipe implements PipeTransform {
  public transform(
    booking?: Booking,
    startFormat = 'EEEE,  dd - MM - yyyy',
    endFormat = 'EEEE,  dd - MM - yyyy',
    showEndTimeAtSameDate = false,
    endTimeAtSameDateFormat = 'HH:mm',
  ): string {
    const startDate = isoToDate(booking?.period_start, true, true);
    const endDate = isoToDate(booking?.period_end, true, true);

    if (isEqual(startDate, endDate)) {
      if (showEndTimeAtSameDate) {
        return `${format(startDate, startFormat)} - ${format(endDate, endTimeAtSameDateFormat)}`;
      }

      return format(startDate, startFormat);
    }

    return `${format(startDate, startFormat)} - ${format(endDate, endFormat)}`;
  }
}
