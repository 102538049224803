import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { ListOptions } from '@capturum/api';
import { responseData } from '@core/utils/converter.utils';
import { Device } from '@features/device/models/device.model';
import { DeviceService } from '@features/device/services/device.service';
import { map } from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '../loader/loader.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-search-results-card',
  templateUrl: './search-results-card.component.html',
  styleUrls: ['./search-results-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CapturumInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgTemplateOutlet,
    LoaderComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SearchResultsCardComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() public title?: string;
  @Input() public inputPlaceholder?: string;

  @ContentChild(CapturumTemplateDirective)
  public template: CapturumTemplateDirective;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasSearched = false;
  public devices: Device[] = [];
  public searchControl: UntypedFormControl;
  public resultCardTemplate: TemplateRef<string>;
  @Input() private domainId: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private deviceService: DeviceService,
    private cdr: ChangeDetectorRef,
  ) {
    this.searchControl = new UntypedFormControl();
  }

  public ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(350),
        map((value) => {
          return this.searchForDevices(value);
        }),
      )
      .subscribe();
  }

  public ngAfterContentInit(): void {
    if (this.template) {
      this.resultCardTemplate = this.template.template;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private searchForDevices(searchTerm: string): void {
    this.loading$.next(true);

    this.hasSearched = true;

    if (!searchTerm) {
      this.devices = [];

      this.loading$.next(false);
      this.cdr.detectChanges();

      return;
    }

    this.getDeviceList(searchTerm);
  }

  private getDeviceList(searchTerm: string): void {
    const options: ListOptions = {
      include: ['domain', 'status', 'images'],
      search: [searchTerm],
      filters: [
        {
          value: this.domainId,
          field: 'categories.radboud_domain_id',
        },
      ],
    };

    this.deviceService
      .index(options)
      .pipe(
        takeUntil(this.destroy$),
        responseData,
        tap(() => {
          return this.loading$.next(false);
        }),
        catchError((error) => {
          this.loading$.next(false);

          return throwError(error);
        }),
      )
      .subscribe((value: Device[]) => {
        this.devices = value;

        this.cdr.detectChanges();
      });
  }
}
