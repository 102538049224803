import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '@features/category/models/category.model';

@Pipe({
  name: 'concatenate',
  standalone: true,
})
export class ConcatenatePipe implements PipeTransform {
  /**
   * Return concatenated items
   *
   * @param list: any[]
   * @param property: string
   * @param glue: string
   * @return string
   */
  public transform(list: any[], property: string, glue = ', '): string {
    return (list ?? [])
      .map((category: Category) => {
        return category[property];
      })
      .join(glue);
  }
}
