import { Component } from '@angular/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { AsyncPipe } from '@angular/common';

@Component({
  template: `
    @if (to.options | observablePipe | async; as options) {
      <cap-multi-select
        [options]="options"
        [formlyAttributes]="field"
        [formControl]="formControl"
        [defaultLabel]="to.translatePlaceholder || to.placeholder | observablePipe | async"
        [disabled]="to.disabled"
        [inputId]="field.key"
        [sortAlphabetically]="to.sortAlphabetically"
        [filter]="to.filter || defaultOptions.templateOptions.filter"
        [filterBy]="to.filterBy || defaultOptions.templateOptions.filterBy"
        [filterPlaceHolder]="to.filterPlaceHolder"
        [appendTo]="to.appendTo"
        (change)="to.change ? to.change(field, $event) : null"
        [scrollHeight]="to.scrollHeight || defaultOptions.templateOptions.scrollHeight"
        [readonly]="to.readOnly"></cap-multi-select>
    }
  `,
  standalone: true,
  imports: [CapturumMultiSelectModule, FormlyModule, FormsModule, ReactiveFormsModule, AsyncPipe, CapturumSharedModule],
})
export class MultiSelectFormlyTypeComponent extends FieldType {
  public defaultOptions = {
    templateOptions: {
      filter: true,
      filterBy: 'label',
      appendTo: null,
      scrollHeight: '200px',
      overlayVisible: false,
      dataKey: 'value',
      defaultLabel: 'Choose',
      onChange: null,
    },
  };
}
