import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { TooltipModule } from 'primeng/tooltip';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-radboud-form-field-wrapper',
  templateUrl: './radboud-form-field.wrapper.html',
  styleUrls: ['./radboud-form-field.wrapper.scss'],
  standalone: true,
  imports: [TooltipModule, AsyncPipe, CapturumSharedModule],
})
export class RadboudFormFieldWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
  public fieldComponent!: ViewContainerRef;
}
