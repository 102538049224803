<div class="bordered-table">
  <cap-info-table
    [columns]="tableColumns"
    [paginator]="paginator"
    [lazyLoading]="true"
    [loading]="loading"
    [stateKey]="stateKey"
    (onLazyLoad)="loadTableData($event)"
    [data]="tableData"
    (onRowClick)="navigateToGroup($event)">
  </cap-info-table>
</div>
