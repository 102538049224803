<div class="sidebar">
  <div class="banner mt-2 d-flex justify-content-center align-items-center flex-column">
    <div class="banner__logo">
      <a [routerLink]="useAdvancedSearch ? '/search-for-devices' : '/'">
        <img [src]="'general.tenant_logo' | setting : ''" [alt]="'general.tenant_name' | setting : ''" />
      </a>
    </div>

    <h1 class="banner__heading text-center mt-4 px-4">{{ 'radboud.sidebar.title' | translate }}</h1>
  </div>

  <div class="menu mt-5">
    @if (domains$ | async; as domains) {
    <p-accordion styleClass="sidebar-accordion-menu" [activeIndex]="activeIndex" #accordion>
      @if (useAdvancedSearch) {
      <p-accordionTab #accordionTab>
        <ng-template pTemplate="header">
          <div class="menu__domain" [class.active]="accordionTab.selected" [routerLink]="'/search-for-devices'">
            <span class="menu__domain__title">{{ 'radboud.menu-item.home.title' | translate }}</span>
          </div>
        </ng-template>
      </p-accordionTab>
      } @for (domain of domains; track domain) {
      <p-accordionTab #accordionTab>
        @if (domain && !domain?.hide) {
        <ng-template pTemplate="header">
          <div
            class="menu__domain"
            id="menu-domain-item-{{ domain?.id }}"
            [class.active]="accordionTab.selected"
            [routerLink]="domain?.is_portal_page ? '/portal' : domain?.routerLink">
            <span class="menu__domain__title">{{ domain?.name }}</span>
          </div>
        </ng-template>
        }
        <ng-template pTemplate="content">
          @if (domain?.categories?.length) {
          <div class="ml-1 mt-2 pb-4">
            @for (category of domain?.categories; track category) {
            <div
              class="menu__categories d-flex align-items-center"
              routerLink="{{ category?.routerLink }}"
              routerLinkActive="active">
              <i class="fas fa-chevron-right mr-3"></i>
              <span class="menu__categories__title">{{ category?.short_name }}</span>
            </div>
            } @if (domain?.has_information_page) {
            <div
              class="menu__categories d-flex align-items-center"
              routerLink="/{{ routes.domain }}/{{ domain?.id }}/{{ routes.information }}"
              routerLinkActive="active">
              <i class="fas fa-chevron-right mr-3"></i>
              <span class="menu__categories__title">
                {{ 'radboud.information-and-software.title' | translate }}
              </span>
            </div>
            }
          </div>
          }
        </ng-template>
      </p-accordionTab>
      }
    </p-accordion>
    <div
      *capIfAuthenticated
      (click)="resetAccordion($event)"
      class="menu__domain"
      routerLink="/{{ routes.userBooking }}"
      routerLinkActive="active">
      <span class="menu__domain__title">{{ 'radboud.sidebar.my-bookings.title' | translate }}</span>
    </div>
    <ng-container *ngxPermissionsOnly="managePermissions">
      <div
        *capIfAuthenticated
        (click)="resetAccordion($event)"
        class="menu__domain"
        routerLink="/{{ routes.manage }}"
        routerLinkActive="active">
        <span class="menu__domain__title">{{ 'radboud.sidebar.manage-application.title' | translate }}</span>
      </div>
    </ng-container>
    } @else {
    <div class="text-center mt-5">
      @for (skeleton of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track skeleton) {
      <cap-skeleton width="75%" height="50px"></cap-skeleton>
      }
    </div>
    }

    <ng-template #skeleton>
      <div class="text-center mt-5">
        @for (skeleton of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track skeleton) {
        <cap-skeleton width="75%" height="50px"></cap-skeleton>
        }
      </div>
    </ng-template>
  </div>

  <div class="version text-center">
    <span class="version__title">v {{ version }}</span>
  </div>
</div>
