import { Injectable } from '@angular/core';
import { Device } from '../models/device.model';
import { Observable } from 'rxjs';
import { User } from '@features/manage-user/models/user.model';
import { ResourceApiService } from '@core/services/resource-api.service';
import { responseData } from '@core/utils/converter.utils';
import { ApiConfig, ApiHttpService, ApiIndexResult, ListOptions, Meta } from '@capturum/api';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@capturum/auth';

@Injectable({
  providedIn: 'root',
})
export class DeviceService extends ResourceApiService<Device> {
  protected endpoint = 'device';
  public scopedUserId: string;

  constructor(
    public apiHttp: ApiHttpService,
    private httpClient: HttpClient,
    private config: ApiConfig,
    private authService: AuthService,
  ) {
    super(apiHttp);
  }

  /**
   * Get the scoped index
   *
   * @param options: ListOptions
   * @return Observable<ApiIndexResult<T>>
   */
  public scopedManageIndex<Device>(options?: ListOptions): Observable<ApiIndexResult<Device>> {
    let endpoint = `/manage/${this.endpoint}`;

    if (this.scopedUserId) {
      endpoint += `/${this.scopedUserId}`;
    }

    return this.apiHttp.get<ApiIndexResult<Device>>(endpoint + this.getOptionsQuery(options));
  }

  /**
   * Get users for impersonation
   *
   * @param device: Device
   * @return Observable<User[]>
   */
  public getUsersForImpersonation(device: Device): Observable<User[]> {
    return this.apiHttp.get(`/${this.endpoint}/${device.id}/impersonation-users`).pipe(responseData);
  }

  /**
   * Get devices for profile page
   *
   * @return Observable<any>
   */
  public getDevicesForProfile(options: ListOptions): Observable<{ data: Device[]; meta: Meta }> {
    let endpoint = `/user/devices`;

    if (this.scopedUserId) {
      endpoint += `/${this.scopedUserId}`;
    }

    return this.apiHttp.get(endpoint + this.getOptionsQuery(options));
  }

  /**
   * Download file from public url
   * @param url public url
   * @returns blob
   */
  public downloadDeviceFile(url: string): Observable<Blob> {
    return this.httpClient.get(url, {
      responseType: 'blob',
    });
  }

  public copyDevice(deviceId: string, numberOfCopies: number): Observable<{ data: Device[] }> {
    return this.apiHttp.post(`/${this.endpoint}/${deviceId}/copy`, { number_of_copies: numberOfCopies });
  }

  public getNumberOfRecipients(deviceId: string, period_start: string, period_end: string): Observable<number> {
    const queryParams = `?period_start=${period_start}&period_end=${period_end}&radboud_device_id=${deviceId}`;

    return this.apiHttp.get(`/${this.endpoint}/${deviceId}/recipients${queryParams}`);
  }

  public updateLocation<T = Device>(id: string, locationId: string): Observable<T> {
    return this.apiHttp.put(`/${this.endpoint}/${id}/location`, { radboud_location_id: locationId });
  }
}
