import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Permission, PermissionIndexedDbModel } from '@capturum/complete';

@Injectable({
  providedIn: 'root',
})
export class CustomPermissionService extends ApiService<Permission> {
  constructor(
    private api: ApiHttpService,
    private ngxPermissionService: NgxPermissionsService,
  ) {
    super(api);
  }

  /**
   * Load the permission and put in IndexedDB
   *
   * @param authenticated: boolean
   * @return Observable<boolean>
   */
  public loadPermissions(authenticated: boolean): Observable<boolean> {
    return new Observable((observer) => {
      if (!authenticated) {
        observer.next(true);
        observer.complete();

        return;
      }

      this.apiHttp
        .get('/role/permission')
        .pipe(
          map((response: any) => {
            return response.data;
          }),
          map((permissions) => {
            return permissions.map((permission) => {
              return { name: permission };
            });
          }),
          switchMap((permissions) => {
            return combineLatest([
              from(PermissionIndexedDbModel.query().clear()),
              from(PermissionIndexedDbModel.query().bulkAdd(permissions)),
              from(PermissionIndexedDbModel.loadPermissions()),
            ]).pipe(
              map(() => {
                return permissions;
              }),
            );
          }),
          tap((permissions) => {
            this.ngxPermissionService.flushPermissions();
            this.ngxPermissionService.loadPermissions(
              permissions.map((permission) => {
                return permission.name;
              }),
            );
          }),
        )
        .subscribe(
          (permissions) => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          },
        );
    });
  }
}
