export enum Entities {
  domain = 'domain',
  booking = 'booking',
  category = 'category',
  device = 'device',
  user = 'user',
  additionalContact = 'additional-contact',
  department = 'department',
  institute = 'institute',
  setting = 'setting',
  translation = 'translation',
  costplace = 'costplace',
  sendMail = 'send-mail',
  deviceGroup = 'device-group',
  profile = 'profile',
  project = 'project',
  reference = 'reference',
  report = 'report',
  company = 'company',
  info = 'info',
  bookingApproval = 'booking-approval',
  location = 'location',
  monsterBooking = 'monster-booking',
  timeslot = 'timeslot',
  searchTileCategory = 'search-tile-category',
  searchForDevices = 'search-for-devices',
}
