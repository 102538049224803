import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@features/manage-user/models/user.model';

@Pipe({
  name: 'userFullName',
  standalone: true,
})
export class UserFullNamePipe implements PipeTransform {
  /**
   * Return the full name of user
   *
   * @param user: User
   * @return string
   */
  public transform(user: User): string {
    return user
      ? (user?.first_name ? user.first_name + ' ' : '') +
          (user?.middle_name ? user.middle_name + ' ' : '') +
          (user?.last_name ? user.last_name : '') +
          (user?.username ? ' (' + user.username + ')' : '')
      : '-';
  }
}
