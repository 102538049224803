import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-html-content-card',
  templateUrl: './html-content-card.component.html',
  styleUrls: ['./html-content-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class HtmlContentCardComponent {
  public safeHtmlContent: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  @Input()
  public set htmlContent(content: string) {
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
