import { Component, Injector, Input, OnInit } from '@angular/core';
import { ManageListComponent } from '@shared/components/manage-list/manage-list.component';
import { DeviceGroup } from '@features/manage-device-group/models/device-group.model';
import { TranslateService } from '@ngx-translate/core';
import { DeviceGroupService } from '@features/manage-device-group/services/device-group.service';
import { Entities } from '@core/enums/general/entities.enum';
import { InfoTableColumnType, CapturumInfoTableModule } from '@capturum/ui/info-table';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/general/routes.enum';
import { BaseDataFilter } from '@core/models/filter.model';

@Component({
  selector: 'app-device-group-list',
  templateUrl: './device-group-list.component.html',
  styleUrls: ['./device-group-list.component.scss'],
  standalone: true,
  imports: [CapturumInfoTableModule],
})
export class FilteredDeviceGroupListComponent extends ManageListComponent<DeviceGroup> implements OnInit {
  @Input() public sortingFilters: BaseDataFilter;
  @Input() public stateKey = 'manage-device-group-list';

  constructor(
    injector: Injector,
    public translateService: TranslateService,
    public apiService: DeviceGroupService,
    public router: Router,
  ) {
    super(injector);

    this.entity = Entities.deviceGroup;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.filters = this.sortingFilters;

    this.tableColumns = [
      {
        field: 'name',
        title: this.translateService.instant('radboud.device-group.name.label'),
        type: InfoTableColumnType.Text,
      },
    ];
  }

  public navigateToGroup({ id }: DeviceGroup): void {
    this.router.navigate([`${AppRoutes.manage}/${Entities.deviceGroup}/${id}`]);
  }
}
