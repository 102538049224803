import { Component } from '@angular/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';

@Component({
  selector: 'app-repeat-section',
  templateUrl: './repeat-section-formly-type.component.html',
  styleUrls: ['./repeat-section-formly-type.component.scss'],
  standalone: true,
  imports: [FormlyModule, CapturumButtonModule, TranslateModule],
})
export class RepeatSectionFormlyTypeComponent extends FieldArrayType {}
