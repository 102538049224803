/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  NgZone,
  Optional,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, first } from 'rxjs/operators';
import { DynamicDialogcontentDirective, CapturumSharedModule } from '@capturum/ui/api';
import { SidebarAction } from '@core/enums/general/sidebar-action.enum';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumButtonModule } from '@capturum/ui/button';

@Component({
  selector: 'app-right-sidebar-int',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SidebarModule, CapturumSharedModule, CapturumButtonModule, TranslateModule],
})
export class RightSidebarComponent implements AfterViewInit {
  @Input() public title: string;
  @Input() public visible: boolean;
  @Input() public showButtons = true;
  @Input() public submitButtonTranslationKey = 'button.submit';
  @Input() public cancelButtonTranslationKey = 'button.cancel';
  @Input() public submitButtonDisabled = false;

  @Output() public onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public onHide: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(Sidebar, { static: true })
  public sidebar: Sidebar;

  @ViewChild(DynamicDialogcontentDirective)
  public insertionPoint: DynamicDialogcontentDirective;

  public componentRef: ComponentRef<any>;
  public childComponentType: Type<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    public config: DynamicDialogConfig,
    public zone: NgZone,
    @Optional() private dialogRef: DynamicDialogRef,
  ) {}

  public ngAfterViewInit(): void {
    if (this.childComponentType) {
      this.loadChildComponent(this.childComponentType);
      this.cdr.detectChanges();

      if (this.config && this.config.data) {
        this.title = this.config.header;
        this.showButtons = this.config.data.showButtons;
      }

      // Make sure the animation is triggered for opening the sidebar
      setTimeout(() => {
        this.visible = true;
        this.visibleChange.emit(true);
        this.cdr.detectChanges();
      }, 50);
    }
  }

  public cancel(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onCancel.emit();
  }

  public submit(): void {
    this.onSubmit.emit();

    if (this.dialogRef) {
      this.dialogRef.close(SidebarAction.submit);
    }
  }

  public hide(): void {
    this.visible = false;
    this.visibleChange.emit(false);
    this.onHide.emit();

    if (this.childComponentType) {
      // Wait for the animation to finish
      setTimeout(() => {
        this.componentRef.destroy();
        this.dialogRef.close();
        this.dialogRef.destroy();
      }, 300);
    }
  }

  public close(): void {
    this.dialogRef.onClose.pipe(
      filter((action) => {
        return action === SidebarAction.close;
      }),
      first(),
    );
  }

  public loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;

    viewContainerRef.clear();
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }
}
