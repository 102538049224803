import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@features/manage-user/models/user.model';

@Pipe({
  name: 'userShortName',
  standalone: true,
})
export class UserShortNamePipe implements PipeTransform {
  /**
   * Return the shortened name of user
   *
   * @param user: User
   * @return string
   */
  public transform(user: User): string {
    return user
      ? `${user?.first_name ? user.first_name?.charAt(0).toUpperCase() : ''}${user?.last_name ? user.last_name : ''}`
      : '';
  }
}
