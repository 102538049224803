import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumInputModule } from '@capturum/ui/input';
import { FieldType, FormlyModule } from '@ngx-formly/core';

@Component({
  template: `
    <cap-input
      [type]="props.type || 'text'"
      [class]="props.styleClass"
      [placeholder]="props.translatePlaceholder || props.placeholder | observablePipe | async"
      [formlyAttributes]="field"
      [autocomplete]="props.autocomplete || 'off'"
      [filter]="props.filter"
      [min]="props.min"
      [max]="props.max"
      [formControl]="formControl"
      [required]="props.required || false | observablePipe | async"
      [iconLeft]="props.iconLeft || null"
      [iconRight]="props.iconRight || null"
      [class.ng-dirty]="showError">
      @if (props.contentLeftText) {
        <ng-template capTemplate="content-left">
          <span>{{ props.contentLeftText }}</span>
        </ng-template>
      }

      @if (props.contentRightText) {
        <ng-template capTemplate="content-right">
          <span>{{ props.contentRightText }}</span>
        </ng-template>
      }
    </cap-input>

    @if (props.hint) {
      <small [innerHTML]="props.hint"></small>
    }
  `,
  standalone: true,
  imports: [CapturumInputModule, FormlyModule, FormsModule, ReactiveFormsModule, CapturumSharedModule, AsyncPipe],
})
export class InputFormlyTypeComponent extends FieldType {}
