import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toUtcDate',
  standalone: true,
})
export class ToUtcDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  public transform(date: string | Date, format?: string): any {
    return this.datePipe.transform(date, format, '+0000');
  }
}
