import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '@capturum/api';
import { ModuleApiModel, ModuleIndexedDbModel } from '@capturum/complete';

@Injectable({
  providedIn: 'root',
})
export class CustomModuleService extends ApiService<ModuleApiModel> {
  /**
   * Resolve the modules and put in IndexedDB
   *
   * @param authenticated: boolean
   * @return Observable<boolean>
   */
  public loadModules(authenticated: boolean): Observable<boolean> {
    return new Observable((observer) => {
      const uri: string = authenticated ? '/role' : '/public';

      this.apiHttp
        .get(`${uri}/module`)
        .pipe(
          map((response: { data: string[] }) => {
            return response.data;
          }),
          switchMap((modules) => {
            return combineLatest([
              from(ModuleIndexedDbModel.query().clear()),
              from(ModuleIndexedDbModel.query().bulkAdd(modules)),
              from(ModuleIndexedDbModel.loadModules()),
            ]);
          }),
        )
        .subscribe(
          () => {
            observer.next(true);
            observer.complete();
          },
          (error) => {
            observer.error(error);
            observer.complete();
          },
        );
    });
  }
}
