import { Injectable } from '@angular/core';
import { SettingService, UserService } from '@capturum/complete';
import { Observable } from 'rxjs';
import { Device } from '@features/device/models/device.model';
import { map } from 'rxjs/operators';
import { User } from '@features/manage-user/models/user.model';
import { responseData } from '@core/utils/converter.utils';
import { Project } from '@features/manage-project/models/project.model';
import { ApiHttpService, ApiIndexResult, ListOptions } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { getLikeValue } from '@core/helpers/like-value.helper';

@Injectable({
  providedIn: 'root',
})
export class CustomUserService extends UserService {
  constructor(
    public apiHttp: ApiHttpService,
    public authService: AuthService,
    private settingService: SettingService,
  ) {
    super(apiHttp, authService);
  }

  /**
   * @param options: ListOptions
   * @return Observable<ApiIndexResult<T>>
   */
  public manageIndex<User>(options?: ListOptions): Observable<ApiIndexResult<User>> {
    const endpoint = `/manage/${this.endpoint}`;

    return this.apiHttp.get<ApiIndexResult<User>>(endpoint + this.getOptionsQuery(options));
  }

  /**
   * @return Observable<Device[]>
   */
  public devicesForDomainAdmin(options?: ListOptions): Observable<Device[]> {
    return this.apiHttp.get(`/${this.endpoint}/domain-admin-devices${this.getOptionsQuery(options)}`).pipe(
      map((response: { data: Device[] }) => {
        return response.data;
      }),
    );
  }

  /**
   * @return Observable<Device[]>
   */
  public availableDevicesForUser(): Observable<Device[]> {
    return this.apiHttp.get(`/${this.endpoint}/available-devices`).pipe(
      map((response: { data: Device[] }) => {
        return response.data;
      }),
    );
  }

  /**
   * @return Observable<User[]>
   */
  public adminsForUserDomains(): Observable<User[]> {
    return this.apiHttp.get(`/${this.endpoint}/domain-admins`).pipe(
      map((response: { data: User[] }) => {
        return response.data;
      }),
    );
  }

  /**
   * @param options?: ListOptions
   * @return Observable<User>
   */
  public getProfile(options?: ListOptions): Observable<User> {
    return this.apiHttp.get(`/${this.endpoint}/profile${this.getOptionsQuery(options)}`).pipe(responseData);
  }

  /**
   * @return Observable<User>
   */
  public updateProfile(data: Partial<User>): Observable<User> {
    return this.apiHttp.put(`/${this.endpoint}/profile/update`, data);
  }

  /**
   * Create a project for the current user
   *
   * @param userId: string
   * @param data: Partial<Project>
   * @return Observable<Project>
   */
  public createProjectForUser(userId: string, data: Partial<Project>): Observable<Project> {
    return this.apiHttp.post(`/${this.endpoint}/${userId}/project`, data).pipe(responseData);
  }

  /**
   * Activate user
   *
   * @param userId: string
   * @return Observable<void>
   */
  public activateUser(userId: string): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}/${userId}/activate`, {});
  }

  /**
   * Deactivate user
   *
   * @param userId: string
   * @return Observable<void>
   */
  public deactivateUser(userId: string): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}/${userId}/deactivate`, {});
  }

  /**
   * Archive user
   *
   * @param userId: string
   * @return Observable<void>
   */
  public archiveUser(userId: string): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}/${userId}/archive`, {});
  }

  /**
   * Export
   *
   * @return Observable<Blob>
   */
  public export(type: string, listOptions?: ListOptions): Observable<Blob> {
    const adjustedOptions = getLikeValue(listOptions);

    return this.apiHttp.getBlob(`/export/${this.endpoint}/${type}${this.getOptionsQuery(adjustedOptions)}`, type);
  }

  /**
   * Export Job
   *
   * @return Observable<Blob>
   */
  public exportByJob(type: string, listOptions?: ListOptions): Observable<{ export_id: string }> {
    const adjustedOptions = getLikeValue(listOptions);

    return this.apiHttp.get(`/export-job/${this.endpoint}/${type}${this.getOptionsQuery(adjustedOptions)}`);
  }

  /**
   * Export Job Download
   *
   * @return Observable<Blob>
   */
  public exportJobDownload(type: string, exportId: string): Observable<Blob> {
    return this.apiHttp.getBlob(`/export-job-download/${exportId}`, type);
  }

  /**
   * Check to see if required values of a user are already saved
   *
   * @return boolean
   */
  public isUserIncomplete(user: User): boolean {
    if (user.is_functional_account) {
      // Users with functional account are not allowed to enter profile page
      return false;
    }

    const requiredUserFields = ['first_name', 'last_name', 'email'];

    if (this.settingService.getValue('radboud.phone_is_mandatory')) {
      requiredUserFields.push('phone');
    }

    // Check if all required fields are set
    if (
      requiredUserFields.some((requiredField) => {
        return user[requiredField] ? user[requiredField] === null : true;
      })
    ) {
      return true;
    }

    if (this.settingService.getValue('radboud.companies_use')) {
      // Check if either a department or a company is set
      if (!user.radboud_department_id && !user.radboud_company_id) {
        return true;
      }
    }

    return false;
  }
}
