import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BlobService {
  constructor(
    private toastService: ToastService,
    private translateService: TranslateService,
    private http: HttpClient,
  ) {}

  /**
   * Download the blob as a file
   *
   * @param response: Blob
   * @param type: string
   * @param fileName: string
   * @return void
   */
  public downloadBlobAsFile(response: Blob, type: string, fileName: string): void {
    if (!response) {
      this.toastService.warning(
        this.translateService.instant('radboud.response.no-content.title'),
        this.translateService.instant('radboud.response.no-content.label'),
      );

      return;
    }

    const reader = new FileReader();

    reader.readAsArrayBuffer(response);

    reader.onloadend = () => {
      const blob = new Blob([reader.result], { type });

      FileSaver.saveAs(blob, fileName);
    };
  }

  public downloadBlobAsFileFromUrl(url: string, filename: string): void {
    const headers = new HttpHeaders();

    headers.delete('Content-Type');

    this.http.get(url, { headers, responseType: 'blob' }).subscribe((file) => {
      FileSaver.saveAs(file, filename);
    });
  }
}
