<div class="radboud-dialog-wrapper">
  <div class="radboud-dialog-wrapper__header">
    <div class="radboud-dialog-wrapper__header__title">
      {{ 'radboud.auth.select-login-option.title' | translate }}
    </div>

    <div class="radboud-dialog-wrapper__header__close-icon">
      <i (click)="close()" class="fas fa-times"></i>
    </div>
  </div>

  <div class="radboud-dialog-wrapper__content">
    <div class="option-card-wrapper">
      <div class="option-card d-flex flex-column items-start">
        <div class="option-card__logo">
          <img [src]="'surfconext.png' | assets: 'images'" alt="surfconext" />
        </div>

        <h4>{{ 'radboud.auth.surfconext.title' | translate }}</h4>

        <p>{{ 'radboud.auth.surfconext.subtitle' | translate }}</p>

        <cap-button
          [label]="'radboud.auth.surfconext.button' | translate"
          (click)="loginService.loginWithSURFconext()"
          styleClass="primary cap-rounded cap-small mr-2">
        </cap-button>
      </div>

      <div class="option-card d-flex flex-column items-start">
        <div class="option-card__logo">
          <i class="fas fa-globe color-primary"></i>
        </div>

        <h4>{{ 'radboud.auth.local-login.title' | translate }}</h4>

        <p>{{ 'radboud.auth.local-login.subtitle' | translate }}</p>

        <cap-button
          [label]="'radboud.auth.local-login.button' | translate"
          (click)="routeToLogin()"
          styleClass="primary cap-rounded cap-small mr-2">
        </cap-button>
      </div>
    </div>
  </div>

  <div class="radboud-dialog-wrapper__footer">
    <div class="radboud-dialog-wrapper__footer__wrapper">
      <cap-button styleClass="secondary" [label]="'button.cancel' | translate" (click)="close()"> </cap-button>
    </div>
  </div>
</div>
