import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@capturum/auth';
import { CustomTranslationService } from '../services/custom-translation.service';

@Injectable()
export class TranslationResolver {
  constructor(
    private translationService: CustomTranslationService,
    private translateService: TranslateService,
    private authService: AuthService,
  ) {}

  /**
   * Resolve the translations
   *
   * @param route: ActivatedRouteSnapshot
   * @param state: RouterStateSnapshot
   * @return Observable<boolean>
   */
  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.translationService.loadTranslations(this.authService.isAuthenticated(), this.authService.getUser());
  }
}
