<app-header></app-header>

<div id="wrapper">
  <app-sidebar></app-sidebar>

  <div class="page-wrapper gray-bg" [class.blurred]="(isDialogOpen$ | async) === true">
    <router-outlet></router-outlet>
  </div>
</div>

<p-confirmDialog
  [header]="'confirm.title' | translate"
  baseZIndex="9999"
  styleClass="radboud-confirm-dialog"
  [closable]="false"
  #cd>
  <p-footer>
    <cap-button icon="fas fa-times" [label]="'general.no' | translate" styleClass="secondary" (click)="cd.reject()">
    </cap-button>

    <cap-button icon="fa fa-check" [label]="'general.yes' | translate" (click)="cd.accept()" styleClass="primary">
    </cap-button>
  </p-footer>
</p-confirmDialog>
