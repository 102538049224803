/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FilePreviewListItem } from '@capturum/ui/file-preview-list/models/file-preview-list-item.model';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumFilePreviewListModule } from '@capturum/ui/file-preview-list';
import { FileDragAndDropDirective } from '../../directives/file-drag-and-drop.directive';

@Component({
  selector: 'app-files-uploader',
  templateUrl: './files-uploader.component.html',
  styleUrls: ['./files-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FileDragAndDropDirective, CapturumFilePreviewListModule, TranslateModule],
})
export class FilesUploaderComponent {
  @ViewChild('fileInput', { static: false })
  public fileInput: HTMLElement;

  @Input() public disabled = false;
  @Input() public files: FilePreviewListItem[];
  @Input() public multiple = false;
  @Input() public acceptedMimeTypes = '*';

  @Output() public onUpload: EventEmitter<FileList> = new EventEmitter<FileList>();
  @Output() public onDelete: EventEmitter<{ name: string; index: number; id?: string }> = new EventEmitter<{
    name: string;
    index: number;
    id?: string;
  }>();

  /**
   * Upload the file(s)
   *
   * @param files: FileList
   * @param event: any
   * @return void
   */
  public upload(files: FileList, event?: any): void {
    this.onUpload.emit(files);

    if (event) {
      event.target.value = '';
    }
  }
}
