@for (field of field.fieldGroup; track field; let i = $index; let isFirst = $first) {
  <div class="repeatable-section-list__item">
    <formly-field class="repeatable-section-list__item__input" [field]="field"></formly-field>
    @if (!form.disabled) {
      <div class="repeatable-section-list__item__trash">
        <i class="fas fa-trash" (click)="remove(i)"> </i>
      </div>
    }
  </div>
}
@if (!form.disabled) {
  <div>
    <cap-button
      [label]="'radboud.button.add-entity.label' | translate: { entity: to.addEntityLabel?.toLowerCase() }"
      (click)="add()"
      icon="fas fa-plus"
      iconPos="left"
      styleClass="secondary mt-3">
    </cap-button>
  </div>
}
