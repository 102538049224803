<div class="device-card">
  <ng-container [ngTemplateOutlet]="deviceCardType === cardTypes.expanded ? expandedCardTemplate : compactCardTemplate">
  </ng-container>

  <ng-template #expandedCardTemplate>
    <div class="device-card__expanded">
      <div class="header">
        <div class="header__icons">
          <div class="d-flex">
            @switch (device?.status?.value) {
              @case (deviceStatuses.green) {
                <i class="status-icon color-success fas fa-check-circle"></i>
              }
              @case (deviceStatuses.yellow) {
                <i class="status-icon color-warning fas fa-times-circle"></i>
              }
              @case (deviceStatuses.red) {
                <i class="status-icon color-danger fas fa-times-circle"></i>
              }
            }

            @if (device?.srf_device || device?.open_to_all) {
              <div class="srf-device ml-2">
                {{ 'radboud.device.open_to_all.label' | translate }}
              </div>
            }
          </div>

          @if (device?.admin_authorized) {
            <div class="edit-button">
              <cap-button
                (click)="editDevice(device)"
                icon="fas fa-pencil"
                styleClass="primary cap-rounded-icon-button cap-icon-button-small">
              </cap-button>
            </div>
          }
        </div>

        <div class="header__image">
          <img [src]="device | deviceImage | default: ('placeholder.png' | assets)" [alt]="device?.short_name" />
        </div>
      </div>

      <div class="content">
        <div class="domain">
          {{ 'radboud.domain.title' | translate }}:
          <p class="color-primary">
            {{ device?.domain?.name }}
          </p>
        </div>
        <div class="title">
          {{ device?.short_name || '-' }}
        </div>
        <div class="description">
          <p>{{ device?.full_name || '-' }}</p>
        </div>

        @if (device?.message && (device | deviceShowMessage) && showMessage) {
          <div class="message">
            {{ device?.message }}
          </div>
        }
      </div>

      <div class="footer">
        <div class="button-wrapper">
          @if (isAuthenticated && device?.authorized) {
            @if (
              device?.status?.value !== deviceStatuses.red ||
              (device?.status?.value === deviceStatuses.red &&
                allowBookingOfDevicesWithAnyStatus &&
                (device?.authorized || device?.admin_authorized))
              ) {
              <cap-button
                (click)="routeToBooking(device)"
                [label]="
                  (device.is_sample_listing
                    ? 'radboud.button.sample-submission.label'
                    : 'radboud.button.book-device.label'
                  ) | translate
                "
                [disabled]="
                  device?.status?.value === deviceStatuses.red &&
                  (!allowBookingOfDevicesWithAnyStatus || (currentUser.is_superuser && currentUser.is_administrator))
                "
                styleClass="primary cap-rounded">
              </cap-button>
            }
          }

          @if (!isAuthenticated && device?.status?.value !== deviceStatuses.red) {
            <cap-button
              (click)="openLoginModal(device?.id)"
              [label]="'radboud.button.sign-in-for-booking.label' | translate"
              styleClass="primary cap-rounded"
              icon="fas fa-sign-in"
              iconPos="right">
            </cap-button>
          }
        </div>

        <div class="button-wrapper">
          <cap-button
            [label]="'radboud.button.show-details.label' | translate"
            (click)="openDeviceDetails(device)"
            styleClass="secondary cap-rounded"
            icon="fas fa-chevron-right"
            iconPos="right">
          </cap-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #compactCardTemplate>
    <div class="device-card__compact">
      <div class="compact-image">
        <img [src]="device | deviceImage | default: ('placeholder.png' | assets)" [alt]="device?.short_name" />
      </div>

      <div class="compact-content">
        <div class="compact-content__icon">
          @switch (device?.status?.value) {
            @case (deviceStatuses.green) {
              <i class="status-icon color-success fas fa-check-circle"></i>
            }
            @case (deviceStatuses.yellow) {
              <i class="status-icon color-warning fas fa-times-circle"></i>
            }
            @case (deviceStatuses.red) {
              <i class="status-icon color-danger fas fa-times-circle"></i>
            }
          }
        </div>

        <div class="domain">
          {{ 'radboud.domain.title' | translate }}:
          <p class="color-primary">{{ device?.domain?.name }}</p>
        </div>
        <div class="title">
          {{ device?.full_name || '-' }}
        </div>
        <div class="description">
          <p>{{ device?.description || '-' | stripHtml }}</p>
        </div>

        <div class="compact-content__footer">
          <cap-button
            [label]="'radboud.button.show-details.label' | translate"
            (click)="openDeviceDetails(device)"
            styleClass="primary cap-rounded cap-small"
            icon="fas fa-chevron-right"
            iconPos="right">
          </cap-button>

          @if (device?.admin_authorized) {
            <cap-button
              (click)="editDevice(device)"
              icon="fas fa-pencil"
              styleClass="secondary cap-rounded-icon-button cap-icon-button-small">
            </cap-button>
          }
        </div>
      </div>
    </div>
  </ng-template>
</div>
