import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  private searchTerm$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  /**
   * Set the search term
   *
   * @param term: string
   * @return void
   */
  public setSearchTerm(term: string): void {
    this.searchTerm$.next(term);
  }

  /**
   * Get the search term as Observable
   *
   * @return Observable<string | null>
   */
  public getSearchTerm(): Observable<string | null> {
    return this.searchTerm$.asObservable().pipe(shareReplay(1));
  }
}
