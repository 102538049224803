import { FieldType, FormlyModule } from '@ngx-formly/core';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';

@Component({
  template: `
    <cap-input-switch
      [formControl]="formControl"
      [style]="to.style || null"
      [styleClass]="to.styleClass || null"
      [disabled]="to.disabled || false"
      [readonly]="to.readonly || false"
      [formlyAttributes]="field">
    </cap-input-switch>
  `,
  standalone: true,
  imports: [CapturumInputSwitchModule, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class InputSwitchFormlyTypeComponent extends FieldType {}
