import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CustomPermissionService } from '@core/services/custom-permission.service';
import { AppRoutes } from '@core/enums/general/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class RuPermissionGuardService {
  constructor(
    private ngxPermissionsService: NgxPermissionsService,
    private permissionService: CustomPermissionService,
    private router: Router,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permissions: string[] = route.data.permissions.only;

    return of(this.ngxPermissionsService.getPermissions()).pipe(
      switchMap((result) => {
        return !Object.keys(result).length ? this.permissionService.loadPermissions(true) : of(result);
      }),
      switchMap(() => {
        return this.ngxPermissionsService.hasPermission(permissions);
      }),
      tap((hasAccess: boolean) => {
        if (!hasAccess) {
          this.router.navigate([AppRoutes.portal]);
        }
      }),
    );
  }
}
