import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeDate',
  standalone: true,
})
export class SafeDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  public transform(date: string | Date, format?: string, isUtc = true): string {
    let transformed = this.datePipe.transform(this.isValidDate(date), format, isUtc ? null : '+0000');

    if (format?.endsWith('HH:mm') && transformed?.endsWith('23:59')) {
      // Show time '23:59' as '24:00'
      transformed = transformed.slice(0, -5) + '24:00';
    }

    return transformed;
  }

  private isValidDate(date: string | Date): string | Date {
    return !isNaN(Date.parse(date as string)) ? date : '';
  }
}
