import { Pipe, PipeTransform } from '@angular/core';
import { AssetType } from '@core/enums/general/asset-type.enum';

@Pipe({
  name: 'assets',
  standalone: true,
})
export class AssetsPipe implements PipeTransform {
  public transform(value: string, type = 'images'): string {
    switch (type) {
      case AssetType.images:
        return `assets/images/${value}`;
      case AssetType.flags:
        return `assets/flags/${value}`;
      case AssetType.svg:
        return `assets/svg/${value}`;
      default:
        return `assets/${value}`;
    }
  }
}
