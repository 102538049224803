import { Booking } from '@features/booking/models/booking.model';
import { TableAction } from '@capturum/ui/api';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideBookingActionByKey',
  standalone: true,
})
export class HideBookingActionByKeyPipe implements PipeTransform {
  public transform(tableActions: TableAction[], key: string, item: Booking): TableAction[] {
    if (item[key]) {
      tableActions.forEach((action) => {
        return (action.hidden = action['key'] === key);
      });
    }

    return tableActions;
  }
}
