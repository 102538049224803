import { ApiIndexResult, ApiListResult } from '@capturum/api';
import { MapItem } from '@capturum/ui/api';
import { Observable } from 'rxjs';

export function apiListResultToMapItem(
  result: ApiListResult,
  valueProperty = 'value',
  labelProperty = 'label',
): MapItem[] {
  return result.data.map((item) => {
    return {
      label: item[labelProperty],
      value: item[valueProperty],
    };
  });
}

export function apiIndexResultToMapItem<T>(
  result: ApiIndexResult<T>,
  valueProperty = 'value',
  labelProperty = 'label',
): MapItem[] {
  return result.data.map((item) => {
    return {
      label: item[labelProperty],
      value: item[valueProperty],
    };
  });
}

export function listResultToMapItems(
  source: Observable<ApiListResult>,
  valueProperty = 'key',
  labelProperty = 'label',
): Observable<MapItem[]> {
  return new Observable((subscriber) => {
    source.subscribe({
      next(value: ApiListResult): void {
        if (value !== undefined && value !== null) {
          subscriber.next(apiListResultToMapItem(value, valueProperty, labelProperty));
        }
      },
      error(error: any): void {
        subscriber.error(error);
      },
      complete(): void {
        subscriber.complete();
      },
    });
  });
}

export function indexResultToMapItems<T>(
  source: Observable<ApiIndexResult<T>>,
  valueProperty = 'key',
  labelProperty = 'label',
): Observable<MapItem[]> {
  return new Observable((subscriber) => {
    source.subscribe({
      next(value: ApiIndexResult<T>): void {
        if (value !== undefined && value !== null) {
          subscriber.next(apiIndexResultToMapItem<T>(value, valueProperty, labelProperty));
        }
      },
      error(error: any): void {
        subscriber.error(error);
      },
      complete(): void {
        subscriber.complete();
      },
    });
  });
}

export function responseData(source: Observable<{ data: any }>): Observable<any> {
  return new Observable((subscriber) => {
    source.subscribe({
      next(value: ApiListResult): void {
        if (value) {
          subscriber.next(value.data);
        }

        subscriber.complete();
      },
      error(error): void {
        subscriber.error(error);
      },
    });
  });
}

export function observableToMapItems(
  source: Observable<any[]>,
  valueProperty = 'key',
  labelProperty = 'label',
): Observable<MapItem[]> {
  return new Observable((subscriber) => {
    source.subscribe({
      next(value: any[]): void {
        if (value !== undefined && value !== null) {
          subscriber.next(toMapItems(value, valueProperty, labelProperty));
        }
      },
      error(error: any): void {
        subscriber.error(error);
      },
      complete(): void {
        subscriber.complete();
      },
    });
  });
}

export function toMapItems(source: any[], valueProperty = 'key', labelProperty = 'label'): MapItem[] {
  return source.map((item) => {
    return {
      label: item[labelProperty],
      value: item[valueProperty],
    };
  });
}

export function mapItemsFirstEmpty(items: MapItem[], label = ''): MapItem[] {
  return [
    {
      label: label,
      value: null,
    },
    ...items,
  ];
}

export const sortMapItemLabel = (a: MapItem, b: MapItem): number => {
  return a?.label.localeCompare(b?.label);
};

export function firstLetterUpperCase(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}
